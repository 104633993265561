import {SignalAnomaly} from '@hconnect/common/components/kmsStatus'
import {HttpError} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {createRetryFunction} from '../../../shared/helpers/queryHelpers'
import {useAxios} from '../../../shared/hooks/useApi'

import {usePlantUpmId} from './usePlantUpmId'

export const QueryKey = 'sensor-anomalies'

export const useGetSensorsAnomalies = (
  kilnId: string,
  options?: UseQueryOptions<SignalAnomaly[], AxiosError<HttpError>>
) => {
  const axiosInstance = useAxios()
  const plantUpmId = usePlantUpmId()

  return useQuery<SignalAnomaly[], AxiosError<HttpError>>({
    queryKey: [QueryKey, plantUpmId, kilnId],
    queryFn: async () => {
      const path = `/kms/${plantUpmId}/signal/threshold-violations`
      return (
        await axiosInstance.get<SignalAnomaly[]>(path, {
          params: {
            departmentId: kilnId
          }
        })
      ).data
    },
    refetchInterval: 1000 * 30,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: (_, query) => query.state.data === undefined,
    retry: createRetryFunction(3),
    retryDelay: 1000 * 3,
    enabled: plantUpmId !== undefined,
    // allow the callee to override the default options
    ...options
  })
}
