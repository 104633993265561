import {Box, CircularProgress} from '@mui/material'
import {useMemo} from 'react'

import {BaseChart} from '../../../../shared/components/graphs/BaseChart'
import {areaChartOptions} from '../../../../shared/components/graphs/config/multiLineChart.config'
import {ChartError} from '../../components/ChartError'
import {useSensors} from '../../context/SensorsContext'
import {useGetSignalPlotData} from '../../hooks/useGetSignalPlotData'

import {getMultiSignalData, getMultiSignalYAxes} from './chartHelpers'

interface SingleSensorChartProps {
  sensorIds: string[]
  deltaHours?: number
  scaleToShowThreshold?: boolean
}

export const MultiSensorChart = ({
  sensorIds,
  deltaHours,
  scaleToShowThreshold
}: SingleSensorChartProps) => {
  const {sensorData} = useSensors()
  const {data, isPending: isLoading} = useGetSignalPlotData({
    sensorIds: sensorIds,
    deltaHours,
    normalized: true
  })

  const chartData = useMemo(() => getMultiSignalData(sensorData, data), [sensorData, data])

  const options = useMemo(() => {
    const yAxes = getMultiSignalYAxes(chartData, scaleToShowThreshold)
    return areaChartOptions({scaleToShowThreshold: scaleToShowThreshold, yAxes: yAxes})
  }, [scaleToShowThreshold, chartData])

  return isLoading ? (
    <Box sx={{height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
  ) : !isLoading && !chartData.labels?.length ? (
    <ChartError />
  ) : (
    <BaseChart data={chartData} options={options} />
  )
}
