import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../../shared/hooks/useApi'

export const useStoreUserValue = () => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({value, keyIdentifier}: {value: string; keyIdentifier: string}) => {
      const path = `/distributeduserstorage`
      return (
        await axiosInstance.post<ResponseType>(path, {
          keyIdentifier,
          value
        })
      ).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['userStorageQuery']})
      return data
    }
  })
}
