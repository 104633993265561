import {HttpError} from '@hconnect/common/types'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../../../shared/hooks/useApi'
import {usePlantUpmId} from '../../../../shared/hooks/usePlantUpmId'
import {AssetConfigurationDto} from '../KilnConfig.types'

export const QueryKey = 'asset-config'

export const useGetAssetConfig = (assetId: string) => {
  const axiosInstance = useAxios()
  const plantUpmId = usePlantUpmId()

  return useQuery<AssetConfigurationDto, AxiosError<HttpError>>({
    queryKey: [QueryKey, plantUpmId, assetId],
    queryFn: async () => {
      const path = `asset-config/kms-data/plant/${plantUpmId}/upm-type/Department`
      return (
        await axiosInstance.get<AssetConfigurationDto>(path, {
          params: {
            typeUpmId: assetId
          }
        })
      ).data
    },
    // if config not created yet, we will get 404
    throwOnError: (error) => error?.response?.status !== 404,
    retry: (retryCount, error) => error?.response?.status !== 404 && retryCount < 3,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: plantUpmId !== undefined && assetId !== undefined
  })
}
