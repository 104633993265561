import {
  LastModelBreach,
  SignalStatus,
  ThresholdLimit
} from '@hconnect/common/components/kmsStatus/types'

export const signalProviders = ['PxTrend', 'Dalog', 'MachineSentry', 'Schaeffler'] as const
export type SignalSource = (typeof signalProviders)[number]

export type SignalsListType = {
  localName: string
  source: SignalSource
  name: string
  description: string
  area: UpmEntityMetadata
  equipment: UpmEntityMetadata
  productionLine: UpmEntityMetadata
  department: UpmEntityMetadata
  plant: UpmEntityMetadata
  id: string
}

type UpmEntityMetadata = {
  id: string
  name: string
}

type SensorThresholds = {
  high: number
  highHigh: number
  highStatus: SignalStatus
  highHighStatus: SignalStatus
  low: number
  lowLow: number
  lowStatus: SignalStatus
  lowLowStatus: SignalStatus
}

interface ApiSignalData {
  localName: string
  unit: string
  values: ApiSignalValues
  thresholds?: Partial<SensorThresholds>
}

type ApiSignalValues = {
  avgValues: number[]
  minValues?: number[]
  maxValues?: number[]
  thresholdStatus?: string[]
  valuesNormalized?: number[]
  modelMin?: number[]
  modelMax?: number[]
  modelMean?: number[]
}

export interface SignalGraphData {
  timeFrom: string
  timeTo: string
  bucketSize: string
  data: ApiSignalData[]
  labels: string[]
  normalizeType: NormalizeType
}

export enum NormalizeType {
  NONE = 'None',
  LOCAL_MIN_MAX = 'LocalMinMax',
  GLOBAL_MIN_MAX = 'GlobalMinMax'
}

export type SignalTelemetry = {
  dateTime: string
  localName: string
  value?: number
  unit?: string
  name?: string
  description?: string
  threshold: {
    status: SignalStatus
    limit: ThresholdLimit
  }
  lastModelBreach?: LastModelBreach
}

export type SignalMetadata = {
  localName: string
  description: string
  area: string
  equipment: string
  productionLine: string
  source: string
  svgId: string
  group?: string
}

export type UserSignalGroup = {
  name: string
  signals: string[]
  plantId: string
  alarms?: number
  warnings?: number
  modelBreaches?: number
}
