import {useQuery} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

export const QueryKey = 'kmsPlants'

export const useGetPlantUpmId = () => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')

  return useQuery({
    queryKey: [QueryKey, plantId],
    queryFn: async () => {
      const path = `/kms/plants/${plantId}`
      return (await axiosInstance.get<{upmId: string}>(path)).data
    },
    staleTime: Infinity
  })
}
