import {KilnInfo} from '@hconnect/common/components/kmsStatus'
import {Loader} from '@hconnect/uikit/src/lib2'
import React, {useContext, createContext, useMemo} from 'react'

import {useGetPlantUpmId} from '../../shared/hooks/useGetPlantUpmId'
import {useGetKilnInfo} from '../hooks/useGetKilnInfo'

interface KilnContext {
  kilnId: string
  plantKilns: KilnInfo[]
  kilnInfo: KilnInfo
  isWidget: boolean
}

export const KilnContext = createContext<KilnContext>(null as unknown as KilnContext)

type KilnProviderProps = {
  children: React.ReactNode
  kilnId: string
  isWidget?: boolean
}

export const KilnProvider = ({children, kilnId, isWidget}: KilnProviderProps) => {
  const {isLoading, data: upmPlant} = useGetPlantUpmId()
  const {data: plantKilns, isPending} = useGetKilnInfo(upmPlant?.upmId)

  const kilnInfo = useMemo(
    () => plantKilns?.find((kiln) => kiln.id === kilnId),
    [kilnId, plantKilns]
  )

  if (isPending || isLoading) return <Loader color="grey" height="30vh" />

  // We cannot proceeed without this data so better crash the app early
  if (!plantKilns) throw new Error('No kilns defined in asset config.')
  if (!kilnInfo) throw new Error(`Kiln ${kilnId} not found in asset config.`)

  return (
    <KilnContext.Provider
      value={{
        kilnId,
        kilnInfo,
        plantKilns,
        isWidget: !!isWidget
      }}
    >
      {children}
    </KilnContext.Provider>
  )
}

export const useKiln = () => useContext(KilnContext)
