import {RollerParts, StationParts} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {
  AxialBalanceConfig,
  AxialBalanceSignals,
  KilnLayoutState,
  SignalsAssetPartMap
} from '@hconnect/common/components/kmsStatus/types'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Box,
  Grid,
  TextField
} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getAssetPartSignals} from './KilnConfig.helpers'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'
import {SignalAutocomplete} from './SignalAutocomplete'

const initAxialBalanceConfig = ({
  stationId,
  kilnLayout,
  rollerPosition
}: {
  stationId: string
  kilnLayout: KilnLayoutState
  rollerPosition: StationParts.SUPPORT_ROLLER_TOP | StationParts.SUPPORT_ROLLER_BOTTOM
}): AxialBalanceConfig => {
  const rollerPartId = `${stationId}_${rollerPosition}`
  const axialBalance = kilnLayout?.axialBalanceSignals?.[rollerPartId]

  return {
    stationId,
    rollerPosition,
    partId: `${rollerPartId}_${RollerParts.SUPPORT_ROLLER_ROLLER}`,
    signal: axialBalance?.signal ?? null,
    position1Threshold: axialBalance?.position1Threshold?.toString() ?? '',
    position2Threshold: axialBalance?.position2Threshold?.toString() ?? ''
  }
}

export const AxialBalanceModal = ({handleClose}: {handleClose: () => void}) => {
  const {t} = useTranslation()
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()
  const [axialBalanceSignals, setAxialBalanceSignals] = useState<AxialBalanceSignals>(() => {
    return kilnLayout.stations.reduce((acc, station) => {
      return {
        ...acc,
        [`${station.id}_${StationParts.SUPPORT_ROLLER_TOP}`]: initAxialBalanceConfig({
          stationId: station.id,
          kilnLayout,
          rollerPosition: StationParts.SUPPORT_ROLLER_TOP
        }),
        [`${station.id}_${StationParts.SUPPORT_ROLLER_BOTTOM}`]: initAxialBalanceConfig({
          stationId: station.id,
          kilnLayout,
          rollerPosition: StationParts.SUPPORT_ROLLER_BOTTOM
        })
      }
    }, {} as AxialBalanceSignals)
  })

  const doSubmit = () => {
    dispatch({
      type: LayoutActionType.SET_AXIAL_BALANCE_SIGNALS,
      payload: axialBalanceSignals
    })
    handleClose()
  }

  const handleChange = (property: Pick<AxialBalanceConfig, 'signal'>, rollerPartId: string) => {
    setAxialBalanceSignals((prevCrankSignals) => ({
      ...prevCrankSignals,
      [rollerPartId]: {...prevCrankSignals[rollerPartId], ...property}
    }))
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle component="div" flexDirection="column" display="flex">
        <Typography variant="h3">{t('kilnMetrics.axialBalance')}</Typography>
      </DialogTitle>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 5}}>
        <Alert severity="warning" sx={{width: '100%'}}>
          For each roller please select the relevant Axial movement signal and the thresholds for
          Position 1 and 2. Only signals mapped to the &apos;Roller&apos; part of each station are
          available.
          <Box display="flex" justifyContent="center" pt={2}>
            <AxialMovementSvg />
          </Box>
        </Alert>

        {kilnLayout?.stations.map((station) => (
          <Box display="flex" flexDirection="column" key={station.id} gap={1} alignItems="center">
            <Typography variant="h3">{station.name ?? station.id}</Typography>
            <Grid container spacing={2}>
              <RollerMovementConfig
                stationId={station.id}
                supportRoller={StationParts.SUPPORT_ROLLER_TOP}
                axialBalance={
                  axialBalanceSignals[`${station.id}_${StationParts.SUPPORT_ROLLER_TOP}`]
                }
                onChange={handleChange}
                selectedSignals={kilnLayout.selectedSignals}
                label="First Roller"
              />
              <RollerMovementConfig
                stationId={station.id}
                supportRoller={StationParts.SUPPORT_ROLLER_BOTTOM}
                axialBalance={
                  axialBalanceSignals[`${station.id}_${StationParts.SUPPORT_ROLLER_BOTTOM}`]
                }
                onChange={handleChange}
                selectedSignals={kilnLayout.selectedSignals}
                label="Second Roller"
              />
            </Grid>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button startIcon={<Close />} variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={<Check />}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          sx={{marginLeft: 1}}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const RollerMovementConfig = ({
  stationId,
  supportRoller,
  axialBalance,
  onChange,
  selectedSignals,
  label
}: {
  stationId: string
  supportRoller: StationParts.SUPPORT_ROLLER_TOP | StationParts.SUPPORT_ROLLER_BOTTOM
  axialBalance: AxialBalanceConfig
  onChange: (property: any, rollerPartId: string) => void
  selectedSignals: SignalsAssetPartMap
  label: string
}) => {
  const rollerPartId = `${stationId}_${supportRoller}`
  const {signal, position1Threshold, position2Threshold} = axialBalance

  return (
    <Grid item xs={6} display="flex" flexDirection="column" gap={1} alignItems="center">
      <Typography variant="h5">{label}</Typography>
      <SignalAutocomplete
        label={'Select signal'}
        value={signal ? [signal] : []}
        onChange={(newSignals) => onChange({signal: newSignals?.[0] ?? null}, rollerPartId)}
        options={getAssetPartSignals(
          [`${rollerPartId}_${RollerParts.SUPPORT_ROLLER_ROLLER}`],
          selectedSignals
        )}
        AutocompleteProps={{
          getOptionDisabled: () => !!signal
        }}
      />
      <Typography variant="h5">Thresholds</Typography>
      <Box display="flex" gap={1}>
        <Threshold
          label="Position 1"
          value={position1Threshold}
          onChange={(value) => onChange({position1Threshold: value}, rollerPartId)}
        />
        <Threshold
          label="Position 2"
          value={position2Threshold}
          onChange={(value) => onChange({position2Threshold: value}, rollerPartId)}
        />
      </Box>
    </Grid>
  )
}

const Threshold = ({
  value,
  label,
  onChange
}: {
  value: string
  label: string
  onChange: (value: string) => void
}) => {
  return (
    <TextField
      label={label}
      variant="filled"
      sx={{'& .MuiFilledInput-root': {backgroundColor: 'transparent !important'}}}
      value={value}
      onChange={(e) => {
        const parsedValue = e.target.value.replaceAll(',', '.')
        if (/^\d{0,3}(\.\d{0,2})?$/.test(parsedValue)) {
          onChange(parsedValue)
        }
      }}
    />
  )
}

const AxialMovementSvg = (props) => (
  <svg width={400} height={80} xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0} y={20} width={120} height={40} fill="#6A9FB5" />
    <text x={60} y={45} fontSize={16} fill="white" textAnchor="middle">
      {'Position 1'}
    </text>
    <rect x={120} y={20} width={160} height={40} fill="#B0B0B0" />
    <text x={200} y={45} fontSize={16} fill="white" textAnchor="middle">
      {'Neutral'}
    </text>
    <rect x={280} y={20} width={120} height={40} fill="#6A9FB5" />
    <text x={340} y={45} fontSize={16} fill="white" textAnchor="middle">
      {'Position 2'}
    </text>
    <line x1={120} y1={10} x2={120} y2={70} stroke="#555" strokeWidth={2} strokeDasharray="5,5" />
    <text x={120} y={10} fontSize={12} fill="#555" textAnchor="middle">
      {'POS1 Threshold'}
    </text>
    <line x1={280} y1={10} x2={280} y2={70} stroke="#555" strokeWidth={2} strokeDasharray="5,5" />
    <text x={280} y={10} fontSize={12} fill="#555" textAnchor="middle">
      {'POS2 Threshold'}
    </text>
  </svg>
)
