import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import {Alert, Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useMemo, useState} from 'react'
import {useBlocker, useLocation, useNavigate} from 'react-router-dom'

import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {useGetPlantUpmId} from '../../../shared/hooks/useGetPlantUpmId'
import {useGetKilnInfo} from '../../hooks/useGetKilnInfo'

import {useCreateAssetConfig} from './api/useCreateAssetConfig'
import {useGetAssetConfig} from './api/useGetAssetConfig'
import {useGetAssetConfigs} from './api/useGetAssetConfigs'
import {useUpdateAssetConfig} from './api/useUpdateAssetConfig'
import {ConfirmDialog} from './ConfirmDialog'
import {ConnectSignalsButton} from './ConnectSignalsButton'
import {CrankSignalsModal} from './CrankSignalsModal'
import {AxialBalanceModal} from './AxialBalanceModal'
import {FinishSetupDialog} from './FinishSetupDialog'
import {HealthIndicators} from './HealthIndicators'
import {HealthIndicatorSignalsModal, HealthIndicatorsModalData} from './HealthIndicatorSignalsModal'
import {assetToSignals} from './hooks/useAssetToSignals'
import {getConnectedSignals, useConnectedSignals} from './hooks/useConnectedSignals'
import {kilnConfigToApiData} from './KilnConfig.helpers'
import {AssetConfigurationDto, LayoutActionType} from './KilnConfig.types'
import {KilnConfigSettingsSteps} from './KilnConfigSettingsSteps'
import {KilnLayout} from './KilnLayout'
import {KilnLayoutProvider, useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'
import {SignalsDisconnectWarning} from './SignalsDisconnectWarning'
import {SignalsModal} from './SignalsModal'
import {SignalsModalData} from './SignalsModal.types'

export const KilnConfig = () => {
  const kilnId = useUrlParam('kilnId')
  const [open, setOpen] = useState(true)
  const [block, setBlock] = useState<boolean>(true)
  const [location, setLocation] = useState<string>('')
  const currentLocation = useLocation()
  const navigate = useNavigate()
  const handleClose = () => {
    setOpen(false)
  }

  const {isLoading: upmLoading, data: upmPlant} = useGetPlantUpmId()
  const {data, isLoading: configLoading, error} = useGetAssetConfig(kilnId)
  const {mutate: createAssetConfig, isPending: createAssetLoading} = useCreateAssetConfig()
  const {mutate: updateAssetConfig, isPending: updateAssetLoading} = useUpdateAssetConfig()

  const {data: kilns} = useGetKilnInfo(upmPlant?.upmId)
  const otherKilns = useMemo(
    () => (kilns ? kilns.filter((k) => k.id !== kilnId) : []),
    [kilnId, kilns]
  )
  const {data: otherSignals} = useGetAssetConfigs(otherKilns.map((k) => k.id))
  const disabledSignals = otherSignals
    .map((config) => assetToSignals(config as unknown as AssetConfigurationDto))
    .flat()

  const isLoading = configLoading || upmLoading

  const beforeUnloadHandler = (event) => {
    event.preventDefault()
    event.returnValue = true
  }

  useEffect(() => {
    addEventListener('beforeunload', beforeUnloadHandler)
    return () => {
      removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  const configNotCreated = !isLoading && (error?.response?.status === 404 || !data)

  const blocker = useBlocker(({currentLocation, nextLocation}) => {
    // Reset dialog open state and save new location
    setOpen(true)
    setLocation(nextLocation.pathname)
    return block && currentLocation.pathname !== nextLocation.pathname
  })

  useEffect(() => {
    // After unblocking navigate to selected route
    if (!block && location !== currentLocation.pathname) {
      navigate(location)
    }
  }, [block])

  const allowCancel = () => {
    setBlock(false)
    setOpen(false)
    removeEventListener('beforeunload', beforeUnloadHandler)
  }

  return isLoading ? (
    <Loader color="grey" height="60vh" />
  ) : (
    <KilnLayoutProvider assetConfig={data}>
      {blocker.state === 'blocked' ? (
        <ConfirmDialog open={open} handleClose={handleClose} setBlock={setBlock} />
      ) : null}
      <KilnConfigContent
        kilnId={kilnId}
        assetConfig={data}
        mutateAssetConfig={configNotCreated ? createAssetConfig : updateAssetConfig}
        isLoading={createAssetLoading || updateAssetLoading}
        allowCancel={allowCancel}
        disabledSignals={disabledSignals}
      />
    </KilnLayoutProvider>
  )
}

const KilnConfigContent = ({
  kilnId,
  assetConfig,
  mutateAssetConfig,
  isLoading,
  allowCancel,
  disabledSignals
}: {
  kilnId: string
  assetConfig?: AssetConfigurationDto
  mutateAssetConfig: (assetConfig: AssetConfigurationDto, options?: any) => void
  isLoading: boolean
  allowCancel: () => void
  disabledSignals: string[]
}) => {
  const [signalsModalData, setSignalsModalData] = useState<SignalsModalData | undefined>()

  const [indicatorsModalData, setIndicatorsModalData] = useState<
    HealthIndicatorsModalData | undefined
  >()
  const [crankSignalsOpen, setCrankSignalsOpen] = useState(false)
  const [axialBalanceOpen, setAxialBalanceOpen] = useState(false)
  const plantId = useUrlParam('plantId')
  const navigate = useNavigate()
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()
  const {enqueueSnackbar} = useSnackbar()
  const [finishDialogOpen, setFinishDialogOpen] = useState(false)
  const [signalsDisconnectWarning, setSignalsDisconnectWarning] = useState<{
    open: boolean
    signals: string[]
    action: (() => void) | null
  }>({
    open: false,
    signals: [],
    action: null
  })

  const connectedSignals = useConnectedSignals({
    selectedSignals: kilnLayout.selectedSignals,
    kilnPartId: kilnLayout.kilnShell.id
  })

  const handleSubmit = () => {
    allowCancel()
    mutateAssetConfig(
      kilnConfigToApiData({
        assetConfig: assetConfig,
        kilnLayout,
        plantId,
        assetId: kilnId
      }),
      {
        onSuccess: () => {
          setTimeout(() => navigate('..'), 1000)
        }
      }
    )
  }

  const handleClose = () => setSignalsModalData(undefined)

  const handleRotationChange = (e, newRotation) => {
    if (newRotation === null) return

    const dispatchActions = () => {
      dispatch({type: LayoutActionType.CHANGE_ROTATION, payload: newRotation})
      if (kilnLayout.drive.position === 'top') {
        dispatch({type: LayoutActionType.SET_BOTTOM_DRIVE})
      }
      if (kilnLayout.drive.position === 'bottom') {
        dispatch({type: LayoutActionType.SET_TOP_DRIVE})
      }
    }

    const connectedSignals = getConnectedSignals(kilnLayout.selectedSignals, kilnLayout.drive.id)

    if (connectedSignals.length > 0 && kilnLayout.drive.position !== 'double') {
      setSignalsDisconnectWarning({
        open: true,
        signals: connectedSignals,
        action: () => {
          dispatchActions()
          enqueueSnackbar(
            `Drive layout changed. Following signals were disconnected: ${connectedSignals.join(
              ', '
            )}`,
            {
              variant: 'warning'
            }
          )
        }
      })
    } else {
      dispatchActions()
    }
  }

  return (
    <Stack spacing={2} maxWidth="fit-content" minWidth="fit-content">
      <Stack direction="row" px={3} justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Setup Kiln</Typography>
        <Box display="flex" height="48px" gap={1}>
          <Button
            variant="text"
            startIcon={<CloseIcon />}
            sx={{color: 'primary.contrastText'}}
            onClick={() => {
              allowCancel()
              navigate(-1)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={<ArrowForwardIcon />}
            onClick={() => setFinishDialogOpen(true)}
          >
            Finish setup
          </Button>
        </Box>
      </Stack>
      <CardBox display="flex" flexDirection="column" gap={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Define Layout</Typography>
          <Alert severity="warning">
            Make sure that the drive position, kiln rotation and material flow are correctly set and
            that all stations are labeled.
          </Alert>
          <Box display="flex" height="48px" gap={1}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => dispatch({type: LayoutActionType.ADD_STATION})}
              startIcon={<AddIcon />}
              sx={{border: '1px solid #E8ECF0'}}
            >
              Add Station
            </Button>
          </Box>
        </Stack>
        <Stack direction="row" spacing={3}>
          <Stack spacing={0.5}>
            <Typography variant="caption">Rotation of Kiln (as viewn from outlet)</Typography>
            <ToggleButtonGroup
              value={kilnLayout.isRotationClockwise}
              exclusive
              onChange={handleRotationChange}
              color="primary"
              sx={{
                height: '48px',
                background: '#F0F2F5',
                border: '1px solid #E8ECF0',
                '& .MuiToggleButtonGroup-grouped': {border: 'none !important'}
              }}
            >
              <ToggleButton value={true} sx={{border: 'none'}}>
                <RotateRightIcon />
                <Typography>Clockwise</Typography>
              </ToggleButton>
              <ToggleButton value={false} sx={{border: 'none'}}>
                <RotateLeftIcon />
                <Typography>Counter Clockwise</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack spacing={0.5}>
            <Typography variant="caption">General Kiln</Typography>
            <ConnectSignalsButton
              connectedSignals={connectedSignals.length}
              onClick={() =>
                setSignalsModalData({
                  type: 'kiln-shell',
                  layout: kilnLayout.kilnShell,
                  disabledSignals
                })
              }
              ButtonProps={{style: {height: '48px'}}}
            />
          </Stack>
          <HealthIndicators
            setCrankSignalsOpen={setCrankSignalsOpen}
            setAxialBalanceOpen={setAxialBalanceOpen}
            setIndicatorsModalData={setIndicatorsModalData}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <KilnLayout
            setSignalsDisconnectWarning={setSignalsDisconnectWarning}
            setSignalsModalData={setSignalsModalData}
            disabledSignals={disabledSignals}
          />
          <KilnConfigSettingsSteps />
        </Stack>
      </CardBox>
      {signalsModalData && (
        <SignalsModal
          kilnId={kilnId}
          handleClose={handleClose}
          signalsModalData={signalsModalData}
        />
      )}
      {indicatorsModalData && (
        <HealthIndicatorSignalsModal
          handleClose={() => setIndicatorsModalData(undefined)}
          healthIndicatorsModalData={indicatorsModalData}
        />
      )}
      <FinishSetupDialog
        open={finishDialogOpen}
        onClose={() => setFinishDialogOpen(false)}
        loading={isLoading}
        isValid={
          kilnLayout.feedSignals.length &&
          kilnLayout.speedSignals.length &&
          kilnLayout.stations.length > 1
        }
        handleSubmit={handleSubmit}
      />
      <SignalsDisconnectWarning
        open={signalsDisconnectWarning.open}
        onClose={() =>
          setSignalsDisconnectWarning({
            open: false,
            signals: [],
            action: null
          })
        }
        onConfirm={() => {
          signalsDisconnectWarning.action && signalsDisconnectWarning?.action()
          setSignalsDisconnectWarning({
            open: false,
            signals: [],
            action: null
          })
        }}
        signals={signalsDisconnectWarning.signals}
      />
      {crankSignalsOpen && <CrankSignalsModal handleClose={() => setCrankSignalsOpen(false)} />}
      {axialBalanceOpen && <AxialBalanceModal handleClose={() => setAxialBalanceOpen(false)} />}
    </Stack>
  )
}
