import {Alert, Box, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import {styled} from '@mui/system'
import {useKilnLayout} from './KilnLayout.context'
import {Done} from '@mui/icons-material'
import {useConnectedSignals} from './hooks/useConnectedSignals'
import {AssetPartSignal} from '@hconnect/common/components/kmsStatus'
import {isArray} from 'lodash'

const StyledWarningListItem = styled(ListItem)(() => ({
  backgroundColor: 'rgb(253, 243, 229)',
  color: 'rgb(95, 55, 0)',
  borderRadius: '8px',
  marginBottom: '8px'
}))

const StyledWarningIcon = styled(ListItemIcon)(({theme}) => ({
  color: theme.palette.warning.main,
  minWidth: 30
}))

const StyledCompletedListItem = styled(ListItem)(() => ({
  backgroundColor: 'rgb(229, 246, 237)',
  color: 'rgb(0, 68, 32)',
  borderRadius: '8px',
  marginBottom: '8px'
}))

const StyledCompletedIcon = styled(ListItemIcon)(({theme}) => ({
  color: theme.palette.success.main,
  minWidth: 30
}))

export const KilnConfigSettingsSteps = () => {
  const kilnLayout = useKilnLayout()

  const renderConditionalItem = (isSuccess: boolean, title: string) => {
    return isSuccess ? (
      <StyledCompletedListItem>
        <StyledCompletedIcon>
          <Done />
        </StyledCompletedIcon>
        <ListItemText primary={title} />
      </StyledCompletedListItem>
    ) : (
      <StyledWarningListItem>
        <StyledWarningIcon>
          <WarningAmberIcon />
        </StyledWarningIcon>
        <ListItemText primary={title} />
      </StyledWarningListItem>
    )
  }

  const isName = (s?: string) => {
    return !!(s && s !== '')
  }

  const stationsNamesCompleted =
    kilnLayout.stations.length > 0 &&
    kilnLayout.stations.map((s) => s.name).reduce((v, s) => (v ? isName(s) : false), true)

  const connectedSignals = useConnectedSignals({
    selectedSignals: kilnLayout.selectedSignals,
    kilnPartId: kilnLayout.kilnShell.id
  })

  const connectedThrustRoller = kilnLayout.stations
    .map((s) => s.thrustRollerPosition)
    .reduce((v, s) => (!v ? s !== 'none' : true), false)

  const stationsSignals =
    kilnLayout.stations.length > 0 &&
    kilnLayout.stations
      .map((s) =>
        Object.keys(kilnLayout.selectedSignals)
          .filter((key) => key.startsWith(s.id))
          .flatMap((key) =>
            kilnLayout.selectedSignals[key].map((signal: AssetPartSignal) => signal.id)
          )
          .filter(Boolean)
      )
      .reduce((v, s) => (v ? isArray(s) && s.length > 0 : false), true)

  const driveSignals =
    Object.keys(kilnLayout.selectedSignals)
      .filter((key) => key.startsWith(kilnLayout.drive.id))
      .flatMap((key) => kilnLayout.selectedSignals[key].map((signal: AssetPartSignal) => signal.id))
      .filter(Boolean).length > 0

  return (
    <Box ml={5} p={2}>
      <Box mb={2}>
        <Typography variant={'h4'}>Kiln Setup Progress</Typography>
      </Box>

      <List>
        <ListItem sx={{padding: 0, marginBottom: '8px'}}>
          <Alert severity={'info'} sx={{width: '100%'}}>
            Check Kiln Rotation
          </Alert>
        </ListItem>
        <ListItem sx={{padding: 0, marginBottom: '8px'}}>
          <Alert severity={'info'} sx={{width: '100%'}}>
            Check Stations placement
          </Alert>
        </ListItem>
        <ListItem sx={{padding: 0, marginBottom: '8px'}}>
          <Alert severity={'info'} sx={{width: '100%'}}>
            Check Drive position
          </Alert>
        </ListItem>
        {renderConditionalItem(stationsNamesCompleted, 'Station labels')}
        {renderConditionalItem(connectedThrustRoller, 'Trust Rollers')}
        {renderConditionalItem(connectedSignals.length > 0, 'General Kiln')}
        {renderConditionalItem(kilnLayout.speedSignals.length > 0, 'Kiln Speed')}
        {renderConditionalItem(kilnLayout.feedSignals.length > 0, 'Kiln Feed')}
        {renderConditionalItem(stationsSignals, 'Stations signals')}
        {renderConditionalItem(driveSignals, 'Drive signals')}
      </List>
    </Box>
  )
}
