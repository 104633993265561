import {dataTestId} from '@hconnect/uikit'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import useDebounce from '@react-hook/debounce'
import React, {useEffect, useRef, useState} from 'react'
import {Tree, TreeApi} from 'react-arborist'

import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {useStoreUserValue} from '../../../../shared/hooks/userStorage/storeValue'
import {UserSignalGroup} from '../../../../shared/types/sensor.types'
import {usePlantMonitoringUrlState} from '../../../hooks/usePlantMonitoringUrlState'
import {useSignalTreeData} from '../../../hooks/useSignalTreeData'
import {Node, TreeNode} from '../SignalTreeNode'

export const AddGroupModal = ({
  isOpen,
  handleClose,
  userGroups
}: {
  isOpen: boolean
  handleClose: () => void
  userGroups: UserSignalGroup[]
}) => {
  const treeData = useSignalTreeData()
  const [search, setSearch] = useDebounce<string>('')
  const [selectedSignals, setSelectedSignals] = useState<string[]>([])
  const {mutateAsync: saveGroup, isPending} = useStoreUserValue()
  const [groupName, setGroupName] = useState<string>()
  const [isError, setIsError] = useState<string>()
  const {activeGroup} = usePlantMonitoringUrlState()
  const treeRef = useRef<TreeApi<TreeNode>>()
  const plantId = useUrlParam('plantId')

  useEffect(() => {
    const group = userGroups.find((g) => g.name === activeGroup)
    const tree = treeRef.current
    if (group) {
      setGroupName(group.name)
      setSelectedSignals(group.signals)
      tree?.setSelection({
        ids: group.signals,
        anchor: null,
        mostRecent: null
      })
    }
  }, [activeGroup, userGroups, treeRef.current])

  const submitGroups = async () => {
    if (!groupName || groupName === '') {
      setIsError('Group name is required')
      return
    }

    const duplicate = userGroups.find((g) => g.name === groupName)

    if (duplicate && duplicate.name !== activeGroup) {
      setIsError('Group name is duplicate please select another name')
      return
    }

    if (selectedSignals.length > 0 && activeGroup) {
      const activeIndex = userGroups.findIndex((g) => g.name === activeGroup)
      userGroups[activeIndex].name = groupName
      userGroups[activeIndex].signals = selectedSignals
      await saveGroup({
        keyIdentifier: 'pm-user-groups',
        value: JSON.stringify(userGroups)
      })
    }

    if (selectedSignals.length > 0 && !activeGroup) {
      userGroups.push({
        name: groupName,
        signals: selectedSignals,
        plantId: plantId
      })
      await saveGroup({
        keyIdentifier: 'pm-user-groups',
        value: JSON.stringify(userGroups)
      })
    }
    onClose()
  }

  const deleteGroup = async () => {
    const groupIndex = userGroups.findIndex((g) => g.name === activeGroup)
    const tmp = [...userGroups]
    if (groupIndex) {
      tmp.splice(groupIndex, 1)
      await saveGroup({
        keyIdentifier: 'pm-user-groups',
        value: JSON.stringify(tmp)
      })
    }

    onClose()
  }

  const onClose = () => {
    setIsError(undefined)
    setGroupName(undefined)
    setSearch('')
    setSelectedSignals([])
    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} aria-labelledby="add-group-modal" fullWidth>
      <DialogTitle id="add-group-modal-title">
        <Typography variant={'h4'}>New group</Typography>
      </DialogTitle>
      <DialogContent sx={{overflowY: 'hidden'}}>
        <TextField
          label={'Group name'}
          fullWidth
          error={isError !== undefined}
          value={groupName ?? ''}
          helperText={isError}
          sx={{marginTop: 2}}
          onChange={(e) => {
            setGroupName(e.target.value)
            setIsError(undefined)
          }}
        />
        <Box mb={2} mt={2}>
          <Divider />
        </Box>
        <Box>
          <TextField
            label="Filter"
            fullWidth
            variant="filled"
            size={'small'}
            defaultValue={search}
            onChange={(e) => setSearch(e.target.value)}
            {...dataTestId('plant-structure-text-filter')}
            sx={{
              '& .MuiFilledInput-root': {
                backgroundColor: 'transparent',
                borderRadius: '6px',
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                '&.Mui-focused': {
                  backgroundColor: 'transparent'
                }
              },
              marginBottom: 3
            }}
          />
          <Tree
            ref={treeRef}
            data={treeData}
            openByDefault={true}
            rowHeight={60}
            width={550}
            indent={14}
            onSelect={(nodes) => {
              const s: string[] = nodes.map((n) => n.data.signalData?.localName ?? '')
              setSelectedSignals(s.filter((i) => i !== ''))
            }}
            searchTerm={search}
            searchMatch={(node) => node.data.label.toLowerCase().includes(search.toLowerCase())}
          >
            {(props) => Node({...props, displayCheckbox: true})}
          </Tree>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{backgroundColor: '#FFF'}}
          width={'100%'}
        >
          <Typography variant={'h3'}>{`${selectedSignals.length} sensors selected`}</Typography>
          <Stack gap={2} direction={'row'}>
            <Button onClick={onClose} variant={'outlined'} disabled={isPending}>
              Cancel
            </Button>
            {activeGroup && (
              <Button
                onClick={deleteGroup}
                color={'error'}
                variant={'contained'}
                disabled={isPending}
              >
                Delete
              </Button>
            )}
            <Button onClick={submitGroups} variant={'contained'} disabled={isPending}>
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
