import {HttpError} from '@hconnect/common'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {createRetryFunction} from '../../../../shared/helpers/queryHelpers'
import {useAxios} from '../../../../shared/hooks/useApi'
import {DistributedCacheResponse, QueryOptionsType} from '../../../../shared/types/common.types'

export const QueryKey = 'userStorageQuery'

export const useGetUserStorage = <TData = DistributedCacheResponse,>({
  keyIdentifier,
  options
}: {
  keyIdentifier: string
  options?: QueryOptionsType<DistributedCacheResponse, AxiosError<HttpError>, TData>
}) => {
  const axiosInstance = useAxios()

  return useQuery<DistributedCacheResponse, AxiosError<HttpError>, TData>({
    queryKey: [QueryKey, keyIdentifier],
    queryFn: async () => {
      const path = '/distributeduserstorage'
      return (
        await axiosInstance.get<DistributedCacheResponse>(path, {
          params: {
            keyIdentifier
          }
        })
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchInterval: 1000 * 30,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: (_, query) => query.state.data === undefined,
    retry: createRetryFunction(3),
    retryDelay: 1000 * 3,
    ...options
  })
}
