import {
  AssetPartSignal,
  SignalsAssetPartMap,
  AssetPartLayout,
  KilnLayoutState,
  AxialBalanceSignals
} from '@hconnect/common/components/kmsStatus/types'

import {AssetConfigurationDto, SignalMapping} from './KilnConfig.types'

export const mergeSignals = ({
  currentSignals,
  newSignals
}: {
  currentSignals: AssetPartSignal[]
  newSignals: AssetPartSignal[]
}) => {
  const newSignalIds = newSignals.map((signal) => signal.id)
  return currentSignals.filter((signal) => !newSignalIds.includes(signal.id)).concat(newSignals)
}

// TODO: this is just first draft of the algorithm
export const calculateXForAssetPart = <T extends AssetPartLayout>({
  draggableElements,
  assetPartTotalWidth
}: {
  draggableElements: T[]
  assetPartTotalWidth: number
}) => {
  const maxOverlayWidth = 140
  const allElements = structuredClone(draggableElements)
  allElements.sort((a, b) => a.x - b.x)

  const adjustedX = (x) => x - 50

  // Check if there is enough space before the first element
  if (allElements.length > 0 && adjustedX(allElements[0].x) >= assetPartTotalWidth) {
    return maxOverlayWidth / 2
  }

  for (let i = 1; i < allElements.length; i++) {
    const previousElement = allElements[i - 1]
    const currentElement = allElements[i]
    if (
      adjustedX(currentElement.x) - (adjustedX(previousElement.x) + maxOverlayWidth) >=
      assetPartTotalWidth
    ) {
      // Found enough space between elements, return the safe x-coordinate
      return adjustedX(previousElement.x) + 2 * maxOverlayWidth
    }
  }

  return 0
}

const createSignalMappings = <T extends AssetPartLayout>(
  selectedSignals: KilnLayoutState['selectedSignals'],
  kilnParts: Array<T>
) => {
  const signalMappings: SignalMapping[] = []

  for (const [svgId, items] of Object.entries(selectedSignals)) {
    const signals = items.map((item) => item.id)
    const kilnPartId = svgId?.split('_')?.[0] // Extract group name from group
    const kilnPart = kilnParts.find((part) => part.id === kilnPartId)
    if (signals.length === 0) continue

    signalMappings.push({
      svgId,
      signals,
      group: kilnPart?.name || kilnPart?.id || 'Unknown Group'
    })
  }

  return signalMappings
}

export const kilnConfigToApiData = ({
  kilnLayout,
  assetConfig,
  plantId,
  assetId
}: {
  kilnLayout: KilnLayoutState
  assetConfig?: AssetConfigurationDto
  plantId: string
  assetId: string
}): AssetConfigurationDto => {
  const ovalitySignals = kilnLayout.ovalitySignals.map((signal) => signal.id)
  // convert crank signals to array of array strings
  const crankSignals = Object.entries(kilnLayout.crankSignals)
    .map(([, value]) => {
      return value.map((signal) => signal.id)
    })
    .filter((signals) => signals.length)

  const axialMovementSignals = getAxialMovementSignals(kilnLayout.axialBalanceSignals)

  return {
    metadata: {
      plantId: plantId,
      upmType: 'Department',
      typeUpmId: assetId,
      configurationType: 'KmsData'
    },
    payload: {
      layout: {
        ...kilnLayout
      },
      configuration: {
        name: assetConfig?.payload.configuration?.name ?? '',
        dimensions: {
          length: assetConfig?.payload.configuration?.dimensions.length ?? 0,
          diameter: assetConfig?.payload.configuration?.dimensions.diameter ?? 0
        },
        isRotationClockwise: kilnLayout.isRotationClockwise,
        feedSignals: kilnLayout.feedSignals.map((signal) => signal.id),
        speedSignals: kilnLayout.speedSignals.map((signal) => signal.id),
        ovalitySignals: ovalitySignals.length ? ovalitySignals : null,
        crankSignals: crankSignals.length ? crankSignals : null,
        axialMovementSignals: axialMovementSignals.length ? axialMovementSignals : null
      },
      signalMappings: createSignalMappings(kilnLayout.selectedSignals, [
        kilnLayout.kilnShell,
        ...kilnLayout.stations,
        kilnLayout.drive
      ])
    }
  }
}

export const getAssetPartSignals = (
  selectors: string[],
  selectedSignals: SignalsAssetPartMap
): AssetPartSignal[] => {
  return Object.entries(selectedSignals)
    .filter(([part]) => selectors.includes(part))
    .flatMap(([, signals]) => signals)
}

export const getAllSelectedSignals = (selectedSignals: SignalsAssetPartMap) => {
  return Object.values(selectedSignals).flat()
}

export const getAxialMovementSignals = (axialBalanceSignals: AxialBalanceSignals | string[]) => {
  if (Array.isArray(axialBalanceSignals)) return []
  return Object.values(axialBalanceSignals)
    .filter((config) => config.signal?.id)
    .map((config) => ({
      localName: config.signal?.id as string,
      position1Threshold: parseFloat(config.position1Threshold) || null,
      position2Threshold: parseFloat(config.position2Threshold) || null
    }))
}
