import {KilnMetricStatus} from '@hconnect/common/components/kmsStatus'
import {dataTestId} from '@hconnect/uikit'
import {ChevronRight, ExpandMore} from '@mui/icons-material'
import {
  Box,
  Stack,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Skeleton,
  Checkbox
} from '@mui/material'
import React from 'react'
import {NodeRendererProps} from 'react-arborist'

import {SignalSourceIcon} from '../../../shared/components/SignalSourceIcon'
import {resolveStatus} from '../../../shared/widgets/AssetHealthWidget'
import {useSignalTelemetry} from '../../context/SignalTelemetryContext'

export type NodeSignalData = {
  source: string
  description: string
  name: string
  localName: string
}

export type TreeNode = {
  id: string
  label: string
  parentId: string
  signalData?: NodeSignalData
  children: TreeNode[] | null
}

export const Node = <T extends TreeNode>({
  node,
  style,
  displayCheckbox
}: NodeRendererProps<T> & {displayCheckbox?: boolean}) => {
  const {label, signalData} = node.data

  if (node.isInternal) {
    return (
      <Stack>
        <InternalListItem
          label={label}
          isOpen={node.isOpen}
          style={style}
          onClick={(e) => {
            e.stopPropagation()
            node.toggle()
          }}
        />
        <Divider variant="middle" sx={{mx: 4}} />
      </Stack>
    )
  }

  if (signalData) {
    return (
      <Stack>
        <SignalListItem
          signalData={signalData}
          style={style}
          displayCheckbox={displayCheckbox}
          isSelected={node.isSelected}
          onClick={(e) => {
            if (e.shiftKey) return
            e.stopPropagation()
            if (node.isSelected) {
              node.deselect()
            } else {
              node.selectMulti()
            }
          }}
        />
        <Divider variant="middle" sx={{mx: 4}} />
      </Stack>
    )
  }

  return null
}

const SignalListItem = ({
  style,
  onClick,
  isSelected,
  signalData,
  displayCheckbox
}: {
  style: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLDivElement>
  isSelected: boolean
  displayCheckbox?: boolean
  signalData: NodeSignalData
}) => {
  const {telemetryData, isPending} = useSignalTelemetry()
  const signal = telemetryData?.[signalData.localName]

  return (
    <ListItem dense={true} disablePadding {...dataTestId(`list-item-${signalData?.localName}`)}>
      <ListItemButton
        style={style}
        sx={{
          backgroundColor: isSelected ? '#E6F0FB' : 'inherit',
          height: 60,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1
        }}
        onClick={onClick}
      >
        {displayCheckbox ? <Checkbox checked={isSelected} /> : undefined}
        <ListItemText
          primary={
            <Box display="flex" alignItems="center" gap={1}>
              <SignalSourceIcon source={signalData?.source ?? ''} />
              <Typography noWrap>{signalData?.localName}</Typography>
            </Box>
          }
          secondary={signalData?.description}
          secondaryTypographyProps={{noWrap: true}}
          sx={{pl: 4}}
        />
        {isPending ? (
          <Skeleton variant="rounded" width={90} height={32} />
        ) : (
          <Box minWidth="fit-content">
            <KilnMetricStatus
              status={resolveStatus({
                thresholdStatus: signal?.threshold?.status,
                modelBreach: signal?.lastModelBreach
              })}
              value={signal?.value ? `${signal.value.toFixed(1)} ${signal?.unit}` : undefined}
            />
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  )
}

const InternalListItem = ({
  label,
  isOpen,
  style,
  onClick
}: {
  label: string
  style: React.CSSProperties
  isOpen: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}) => {
  return (
    <ListItem dense={true} disablePadding {...dataTestId(`list-item-${label}`)}>
      <ListItemButton style={style} onClick={onClick} sx={{backgroundColor: 'inherit', height: 60}}>
        <ListItemText primaryTypographyProps={{noWrap: true}} primary={label} sx={{pl: 4}} />
        <ListItemIcon sx={{display: 'flex', minWidth: 30}}>
          {isOpen ? <ExpandMore color="primary" /> : <ChevronRight color="primary" />}
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  )
}
