import {ThresholdLimit} from '@hconnect/common/components/kmsStatus'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import {Box, Chip, ListSubheader, Menu, Checkbox, ListItem, Typography} from '@mui/material'
import {MouseEventHandler, useState} from 'react'

import {SignalSourceIcon} from '../../../shared/components/SignalSourceIcon'
import {signalProviders} from '../../../shared/types/sensor.types'
import {
  statusOptions,
  StatusOption,
  UrlKey,
  usePlantMonitoringUrlState
} from '../../hooks/usePlantMonitoringUrlState'

const filterOptions = ['Status', 'Provider'] as const
type FilterOption = (typeof filterOptions)[number]

const filterParamMap: Record<FilterOption, UrlKey> = {
  Status: 'status',
  Provider: 'providers'
}

export const SignalTreeFilters = () => {
  const [openFilter, setOpenFilter] = useState<FilterOption | null>(null)
  const {noFiltersSelected, clearAllFilters, updateSearchParams} = usePlantMonitoringUrlState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (filter: FilterOption) => (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenFilter(filter)
    setAnchorEl(event.currentTarget)
  }

  const handleDelete = (filter: FilterOption) => () => {
    updateSearchParams([{key: filterParamMap[filter], value: ''}])
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenFilter(null)
  }

  return (
    <>
      <Box
        gap={1}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: {xs: 'column', sm: 'row'}
        }}
      >
        <Chip
          color={noFiltersSelected ? 'primary' : 'default'}
          sx={{
            fontSize: 16,
            color: noFiltersSelected ? 'default' : '#52667A',
            fontWeight: 500
          }}
          label={'All'}
          onClick={noFiltersSelected ? undefined : clearAllFilters}
        />
        {filterOptions.map((option) => (
          <FilterChip
            key={option}
            open={openFilter === option}
            filterOption={option}
            onClick={handleClick(option)}
            onDelete={handleDelete(option)}
          />
        ))}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {openFilter === 'Status' && <StatusFilterOptions />}
        {openFilter === 'Provider' && <ProviderFilterOptions />}
      </Menu>
    </>
  )
}

const FilterChip = ({
  open,
  filterOption,
  onClick,
  onDelete
}: {
  open: boolean
  filterOption: FilterOption
  onClick: MouseEventHandler<HTMLDivElement>
  onDelete: () => void
}) => {
  const selectedValues = useFilterValues({filterOption})
  const active = selectedValues.length > 0 || open
  const multipleSelected = selectedValues.length > 1
  const oneSelected = selectedValues.length === 1
  const atLeastOneSelected = selectedValues.length > 0

  return (
    <Chip
      key={filterOption}
      color={active ? 'primary' : 'default'}
      label={
        <Box display="flex" gap={0.5}>
          <Typography variant="h5" color={active ? 'rgba(255, 255, 255, 0.60)' : '#52667A'}>
            {filterOption}
          </Typography>
          <Typography variant="h5">
            {oneSelected
              ? selectedValues[0]
              : multipleSelected
                ? `Multiple (${selectedValues.length})`
                : null}
          </Typography>
        </Box>
      }
      onClick={onClick}
      onDelete={atLeastOneSelected ? onDelete : undefined}
      deleteIcon={<CloseIcon sx={{fontSize: '16px !important'}} />}
    />
  )
}

const useFilterValues = ({filterOption}: {filterOption: FilterOption}) => {
  const {providers, status} = usePlantMonitoringUrlState()
  if (filterOption === 'Provider') {
    return providers
  }
  if (filterOption === 'Status') {
    return status
  }
  return []
}

const ProviderFilterOptions = () => {
  const {providers, updateSearchParams} = usePlantMonitoringUrlState()

  return (
    <>
      <ListSubheader sx={{textAlign: 'center'}}>Provider</ListSubheader>
      {signalProviders.map((option) => (
        <ListItem key={option} divider>
          <Checkbox
            size="small"
            checked={providers.includes(option)}
            onChange={(e) => {
              if (e.target.checked) {
                updateSearchParams([
                  {
                    key: 'providers',
                    value: [...providers, option].join(',')
                  }
                ])
              } else {
                updateSearchParams([
                  {
                    key: 'providers',
                    value: providers.filter((p) => p !== option).join(',')
                  }
                ])
              }
            }}
          />
          <Box display="flex" gap={1} alignItems="center">
            <SignalSourceIcon source={option} />
            {option}
          </Box>
        </ListItem>
      ))}
    </>
  )
}

const StatusFilterOptions = () => {
  const {status, updateSearchParams} = usePlantMonitoringUrlState()

  return (
    <>
      {statusOptions.map((option) => (
        <ListItem key={option} divider>
          <Checkbox
            size="small"
            checked={status.includes(option)}
            onChange={(e) => {
              if (e.target.checked) {
                updateSearchParams([
                  {
                    key: 'status',
                    value: [...status, option].join(',')
                  }
                ])
              } else {
                updateSearchParams([
                  {
                    key: 'status',
                    value: status.filter((p) => p !== option).join(',')
                  }
                ])
              }
            }}
          />
          <Box display="flex" gap={1} alignItems="center">
            <StatusIcon status={option} />
            {option}
          </Box>
        </ListItem>
      ))}
    </>
  )
}

const StatusIcon = ({status}: {status: StatusOption}) => {
  switch (status) {
    case ThresholdLimit.HIGH_HIGH:
    case ThresholdLimit.LOW_LOW:
      return <ErrorIcon color="error" />
    case ThresholdLimit.HIGH:
    case ThresholdLimit.LOW:
      return <WarningAmberIcon color="warning" />
    case 'ModelBreach':
      return <WarningIcon style={{color: '#8C3CC9'}} />
    default:
      return null
  }
}
