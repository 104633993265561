import {HttpError} from '@hconnect/common/types'
import {useQuery, UseQueryOptions, keepPreviousData} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Moment} from 'moment'

import {useAxios} from '../../../shared/hooks/useApi'
import {usePlantUpmId} from '../../shared/hooks/usePlantUpmId'
import {SignalGraphData} from '../../shared/types/sensor.types'

export const QueryKey = 'crank-plot-data'

export const useGetCrankPlotData = ({
  sensorIds,
  timeFrom,
  timeTo,
  bucketSize,
  options
}: {
  sensorIds: string[]
  timeFrom: Moment
  timeTo: Moment
  bucketSize?: string
  options?: UseQueryOptions<SignalGraphData, AxiosError<HttpError>>
}) => {
  const axiosInstance = useAxios()
  const plantUpmId = usePlantUpmId()

  const timeFromIso = timeFrom.toISOString()
  const timeToIso = timeTo.toISOString()

  return useQuery<SignalGraphData, AxiosError<HttpError>>({
    queryKey: [QueryKey, plantUpmId, sensorIds, timeFromIso, timeToIso, bucketSize],
    queryFn: async () => {
      const path = `/kms/${plantUpmId}/signal/crank/plot-data`
      return (
        await axiosInstance.get<SignalGraphData>(path, {
          params: {
            localNames: sensorIds,
            timeFrom: timeFromIso,
            timeTo: timeToIso,
            includeValueTypes: ['Min', 'Max']
          }
        })
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: false,
    retry: 3,
    retryDelay: 1000 * 3,
    placeholderData: keepPreviousData,
    enabled: plantUpmId !== undefined,
    // allow the callee to override the default options
    ...options
  })
}
