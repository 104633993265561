import {ThresholdLimit} from '@hconnect/common/components/kmsStatus'
import {useCallback, useMemo} from 'react'
import {useSearchParams} from 'react-router'

import {SignalSource} from '../../shared/types/sensor.types'

export type UrlKey =
  | 'search'
  | 'selected'
  | 'columns'
  | 'activeTreeView'
  | 'scaleToThresholds'
  | 'equipmentOnly'
  | 'status'
  | 'providers'
  | 'activeGroup'
  | 'quickSelection'
  | 'startDate'
  | 'endDate'
  | 'resetZoom'

export const statusOptions = [
  ThresholdLimit.HIGH_HIGH,
  ThresholdLimit.HIGH,
  ThresholdLimit.LOW,
  ThresholdLimit.LOW_LOW,
  'ModelBreach'
] as const

export type StatusOption = (typeof statusOptions)[number]

export const usePlantMonitoringUrlState = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const getSearchParam = (key: UrlKey) => searchParams.get(key)

  const selected = getSearchParam('selected')
  const selectedIds = useMemo(() => selected?.split(',') ?? [], [selected])

  const urlColumns = parseInt(getSearchParam('columns') ?? '')
  const columns = Number.isNaN(urlColumns) ? 1 : urlColumns

  const scaleToThresholds = getSearchParam('scaleToThresholds') !== 'false'
  const equipmentOnly = getSearchParam('equipmentOnly') === 'true'
  const activeTreeView = getSearchParam('activeTreeView') ?? 'signals'
  const activeGroup = getSearchParam('activeGroup') ?? null

  // tree filters
  const search = getSearchParam('search') ?? ''
  const urlProvider = getSearchParam('providers')
  const urlStatus = getSearchParam('status')
  const providers = useMemo(() => (urlProvider?.split(',') ?? []) as SignalSource[], [urlProvider])
  const status = useMemo(() => (urlStatus?.split(',') ?? []) as StatusOption[], [urlStatus])

  // time range
  const startDateUrl = getSearchParam('startDate')
  const endDateUrl = getSearchParam('endDate')
  const quickSelection = getSearchParam('quickSelection')
  const startDate = useMemo(() => (startDateUrl ? new Date(startDateUrl) : null), [startDateUrl])
  const endDate = useMemo(() => (endDateUrl ? new Date(endDateUrl) : null), [endDateUrl])
  const resetZoom = getSearchParam('resetZoom')

  const noFiltersSelected = !urlProvider && !urlStatus

  const clearAllFilters = useCallback(() => {
    setSearchParams((params) => {
      params.delete('status')
      params.delete('providers')
      return params
    })
  }, [setSearchParams])

  const updateSearchParams = useCallback(
    (newParams: {key: UrlKey; value: string}[]) => {
      setSearchParams((params) => {
        newParams.forEach(({key, value}) => {
          if (value === '') {
            params.delete(key)
          } else {
            params.set(key, value)
          }
        })
        return params
      })
    },
    [setSearchParams]
  )

  return {
    activeGroup,
    activeTreeView,
    clearAllFilters,
    columns,
    equipmentOnly,
    noFiltersSelected,
    providers,
    quickSelection,
    scaleToThresholds,
    search,
    selectedIds,
    status,
    startDate,
    resetZoom,
    endDate,
    updateSearchParams
  }
}
