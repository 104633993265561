import {useCallback, useEffect, useState} from 'react'

import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {DistributedCacheResponse} from '../../../shared/types/common.types'
import {useGetUserStorage} from '../../shared/hooks/userStorage/getValue'
import {SignalTelemetry, UserSignalGroup} from '../../shared/types/sensor.types'

export const useGetGroupsData = (telemetryData?: Record<string, SignalTelemetry> | undefined) => {
  const {data: userGroupsData, isPending} = useGetUserStorage({keyIdentifier: 'pm-user-groups'})
  const plantId = useUrlParam('plantId')
  const [userGroups, setUserGroups] = useState<UserSignalGroup[]>([])

  const parseUserGroupsData = useCallback(
    (userGroupsData: DistributedCacheResponse) => {
      if (userGroupsData?.value) {
        try {
          const tmp = JSON.parse(userGroupsData.value)
          if (Array.isArray(tmp)) {
            const items: UserSignalGroup[] = []
            tmp.forEach((t) => {
              if (
                'name' in t &&
                typeof t.name === 'string' &&
                'plantId' in t &&
                typeof t.plantId === 'string' &&
                'signals' in t &&
                Array.isArray(t.signals)
              ) {
                items.push({
                  plantId: t.plantId,
                  name: t.name,
                  signals: t.signals,
                  alarms: 0,
                  warnings: 0,
                  modelBreaches: 0
                })
              }
            })
            return items
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    [plantId]
  )

  useEffect(() => {
    if (userGroupsData) {
      const parsedGroups = parseUserGroupsData(userGroupsData)

      if (parsedGroups && parsedGroups.length) {
        if (telemetryData) {
          const tmpGroups = parsedGroups.map((group) => {
            group.signals.forEach((signal) => {
              if (telemetryData && telemetryData[signal]) {
                const data = telemetryData[signal]
                if (data.lastModelBreach && group.modelBreaches !== undefined)
                  group.modelBreaches += 1
                if (data.threshold) {
                  if (data.threshold.status === 'Warning' && group.warnings !== undefined)
                    group.warnings += 1
                  if (data.threshold.status === 'Alarm' && group.alarms !== undefined)
                    group.alarms += 1
                }
              }
            })
            return group
          })

          setUserGroups(tmpGroups)
        } else {
          setUserGroups(parsedGroups)
        }
      }
    }
  }, [userGroupsData, telemetryData, parseUserGroupsData])

  return {
    userGroups: userGroups,
    isPending
  }
}
