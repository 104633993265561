import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Loader, PageContainer} from '@hconnect/uikit/src/lib2'
import {useEffect} from 'react'
import {Outlet} from 'react-router'
import {Navigate} from 'react-router-dom'

import {useGlobalContext} from '../../../shared/hooks/useGlobalContext'
import {useGetPlantUpmId} from '../../shared/hooks/useGetPlantUpmId'
import {Crank} from '../components/Crank/Crank'
import {KilnConfig} from '../components/KilnConfig/KilnConfig'
import {KMS, KmsContainer} from '../components/KMS'
import {useGetKilnInfo} from '../hooks/useGetKilnInfo'

export const KmsMain = () => {
  const {state} = useGlobalContext()

  useEffect(() => {
    if (state && state.user) {
      trackEvent('kmsUserLoggedIn', {
        userId: state.user.userId,
        country: state.user.countryCode,
        product: 'Maintain',
        subProduct: 'KMS'
      })
    }
  }, [state])
  return <Outlet />
}

const NavigateToFirstKiln = () => {
  const {isLoading: upmLoading, data: upmPlant} = useGetPlantUpmId()
  const {isLoading, data} = useGetKilnInfo(upmPlant?.upmId)

  if (isLoading || upmLoading) return <Loader color="grey" height="60vh" />

  // We cannot proceeed without this data so better crash the app early
  if (!data?.[0].id) throw new Error('No kilns defined in asset config.')

  return <Navigate to={data[0].id} replace />
}

export const KmsRoutes = [
  {
    index: true,
    element: <NavigateToFirstKiln />
  },
  {
    path: ':kilnId/config',
    element: (
      <PageContainer display="flex" flexDirection="column">
        <KilnConfig />
      </PageContainer>
    )
  },
  {
    path: ':kilnId',
    element: <KmsContainer />,
    children: [
      {
        index: true,
        element: <KMS />
      },
      {
        path: 'crank',
        element: <Crank />
      },
      {
        path: '*',
        element: <Navigate to=".." replace />
      }
    ]
  }
]
