import {Dates, isSameDate} from '@hconnect/uikit'
import {QuickSelectionItem} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/types'
import AddIcon from '@mui/icons-material/Add'
import CompressIcon from '@mui/icons-material/Compress'
import ExpandIcon from '@mui/icons-material/Expand'
import RemoveIcon from '@mui/icons-material/Remove'
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor'
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Button,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import moment from 'moment'
import {useEffect, useMemo} from 'react'
import {isInclusivelyAfterDay} from 'react-dates'
import {useTranslation} from 'react-i18next'

import {RangeDatePicker} from '../../../shared/components/filter/RangeDatePicker'
import {usePlantMonitoringUrlState} from '../hooks/usePlantMonitoringUrlState'

export const ControlPanel = () => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent={'flex-end'}
      gap={1}
      flexDirection={{xs: 'column', sm: 'row'}}
    >
      <TimeRange />
      <Columns />
      <ScaleToThresholds />
      <ResetZoom />
    </Box>
  )
}

const Columns = () => {
  const {columns, selectedIds, updateSearchParams} = usePlantMonitoringUrlState()
  const selectedCount = selectedIds.length

  return (
    <Box
      display="flex"
      justifyContent={'space-between'}
      sx={{
        border: '1px solid rgba(255, 255, 255, 0.15)',
        borderRadius: '6px',
        maxHeight: '48px',
        height: '48px',
        flex: '0 1 150px',
        px: 0.5
      }}
      data-test-id="popout-columns"
    >
      <IconButton
        disabled={columns <= 1}
        onClick={() =>
          updateSearchParams([
            {
              key: 'columns',
              value: (columns - 1).toString()
            }
          ])
        }
        edge="end"
        sx={{color: 'common.white', alignSelf: 'center'}}
      >
        <RemoveIcon />
      </IconButton>
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="caption" color="rgba(255, 255, 255, 0.60)">
          Columns
        </Typography>
        <Typography variant="h5">{columns}</Typography>
      </Stack>
      <IconButton
        onClick={() =>
          updateSearchParams([
            {
              key: 'columns',
              value: (columns + 1).toString()
            }
          ])
        }
        disabled={columns >= selectedCount}
        edge="end"
        sx={{color: 'common.white', alignSelf: 'center'}}
        data-test-id="add-column-btn"
      >
        <AddIcon />
      </IconButton>
    </Box>
  )
}

const ScaleToThresholds = () => {
  const {t} = useTranslation()
  const {scaleToThresholds, updateSearchParams} = usePlantMonitoringUrlState()

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={scaleToThresholds}
      onChange={() =>
        updateSearchParams([
          {
            key: 'scaleToThresholds',
            value: (!scaleToThresholds).toString()
          }
        ])
      }
      sx={{
        background: '#01356A',
        display: 'flex',
        border: '1px solid rgba(255, 255, 255, 0.13)',
        height: '48px',
        '& .MuiToggleButton-root': {
          borderRadius: '6px !important',
          flexGrow: 1,
          ':not(.Mui-selected):hover': {
            background: 'transparent !important'
          }
        }
      }}
    >
      <ToggleButton
        value={true}
        aria-label={t('chartSettings.enable')}
        sx={{color: 'common.white'}}
      >
        <ExpandIcon sx={{color: 'common.white'}} />
      </ToggleButton>
      <ToggleButton value={false} aria-label={t('chartSettings.disable')}>
        <CompressIcon sx={{color: 'common.white'}} />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const ResetZoom = () => {
  const {updateSearchParams} = usePlantMonitoringUrlState()

  return (
    <Button
      variant="contained"
      startIcon={<YoutubeSearchedForIcon />}
      sx={{
        flex: '0 1 150px',
        height: '48px',
        maxHeight: '48px',
        background: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid rgba(255, 255, 255, 0.15)',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          borderColor: 'rgba(255, 255, 255, 0.6)',
          boxShadow: 'none'
        }
      }}
    >
      <Typography
        noWrap
        sx={{fontWeight: 500}}
        onClick={() => updateSearchParams([{key: 'resetZoom', value: 'true'}])}
      >
        Reset zoom
      </Typography>
    </Button>
  )
}

const TimeRange = () => {
  const {quickSelection, updateSearchParams, startDate, endDate} = usePlantMonitoringUrlState()

  const selectionItems: QuickSelectionItem[] = useMemo(
    () => [
      {
        id: 'last30days',
        title: 'Last 30 days',
        dates: [moment().subtract(30, 'days').toDate(), moment().toDate()]
      },
      {
        id: 'last7days',
        title: 'Last 7 days',
        dates: [moment().subtract(7, 'days').toDate(), moment().toDate()]
      },
      {
        id: 'last3days',
        title: 'Last 3 days',
        dates: [moment().subtract(3, 'days').toDate(), moment().toDate()]
      },
      {
        id: 'last24hours',
        title: 'Last 24 hours',
        dates: [moment().subtract(24, 'hours').toDate(), moment().toDate()]
      },
      {
        id: 'last12hours',
        title: 'Last 12 hours',
        dates: [moment().subtract(12, 'hours').toDate(), moment().toDate()]
      },
      {
        id: 'last6hours',
        title: 'Last 6 hours',
        dates: [moment().subtract(6, 'hours').toDate(), moment().toDate()]
      }
    ],
    []
  )

  const selectedItem = useMemo(
    () => selectionItems.find((item) => item.id === quickSelection),
    [quickSelection, selectionItems]
  )

  const dateRange: [Date | null, Date | null] = useMemo(
    () => [startDate, endDate],
    [startDate, endDate]
  )

  const handleChange = (value: Date | Dates<Date>) => {
    if (value instanceof Date) {
      updateSearchParams([
        {
          key: 'startDate',
          value: moment(value).startOf('day').toISOString()
        },
        {
          key: 'endDate',
          value: moment(value).endOf('day').toISOString()
        }
      ])
    } else {
      updateSearchParams([
        {
          key: 'startDate',
          value: value?.startDate?.toISOString() || ''
        },
        {
          key: 'endDate',
          value: value?.endDate?.toISOString() || ''
        }
      ])
    }
  }

  // Reconcile quick selection
  // if custom date range is provided do nothing, otherwise set default quick selection or update dates of preselected one
  useEffect(() => {
    if (!quickSelection && startDate) return
    const defaultItem = selectionItems.find((item) => item.id === 'last24hours')
    const urlItem = quickSelection
      ? selectionItems.find((item) => item.id === quickSelection)
      : null

    const effectiveItem = urlItem || defaultItem

    if (!effectiveItem) return
    updateSearchParams([
      {
        key: 'quickSelection',
        value: effectiveItem.id || ''
      },
      {
        key: 'startDate',
        value: effectiveItem.dates[0].toISOString()
      },
      {
        key: 'endDate',
        value: effectiveItem.dates[1]?.toISOString() || ''
      }
    ])
  }, [quickSelection, selectionItems, startDate, updateSearchParams])

  return (
    <RangeDatePicker
      dateRange={dateRange}
      onChange={handleChange}
      quickSelectionItems={selectionItems}
      selectedItem={selectedItem}
      onQuickSelection={(item) => {
        updateSearchParams([
          {
            key: 'quickSelection',
            value: item?.id || ''
          }
        ])
      }}
      isOutsideRange={(day: Date) =>
        isInclusivelyAfterDay(moment(day), moment()) && !isSameDate(day, new Date())
      }
      DatePickerTextFieldProps={{
        sx: {
          border: '1px solid rgba(255, 255, 255, 0.15)',
          borderRadius: '6px',
          maxHeight: '48px',
          height: '48px',
          flex: '1 1 200px',
          '& .MuiTypography-h4': {color: 'white', fontSize: '15px'},
          '& .MuiSvgIcon-root': {color: 'white', fontSize: '15px'},
          '& .MuiTypography-caption': {color: 'rgba(255, 255, 255, 0.60)'}
        }
      }}
    />
  )
}
