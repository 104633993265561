import {KilnMetricStatus} from '@hconnect/common/components/kmsStatus'
import {dataTestId} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@mui/material'
import React, {useMemo, useState} from 'react'

import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {ReactComponent as GroupsEmptyState} from '../../../../shared/icons/groups_empty_state.svg'
import {UserSignalGroup} from '../../../../shared/types/sensor.types'
import {useSignalTelemetry} from '../../../context/SignalTelemetryContext'
import {useGetGroupsData} from '../../../hooks/useGetGroupsData'
import {usePlantMonitoringUrlState} from '../../../hooks/usePlantMonitoringUrlState'

import {AddGroupModal} from './AddGroupModal'

const GroupsEmpty = ({
  setAddGroupModalOpen
}: {
  setAddGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Box px={3} sx={{display: 'flex', justifyContent: 'center', margin: '32px'}}>
      <Box sx={{textAlign: 'center'}}>
        <GroupsEmptyState />
        <Typography variant={'h1'} mt={4} mb={4}>
          No groups available
        </Typography>
        <Typography sx={{color: '#54708C'}} mb={4}>
          Create your first group by selecting multiple sensors or by clicking the button below.
        </Typography>
        <Button
          variant={'contained'}
          startIcon={<AddIcon />}
          onClick={() => setAddGroupModalOpen(true)}
        >
          New group
        </Button>
      </Box>
    </Box>
  )
}

export const Groups = () => {
  const {telemetryData} = useSignalTelemetry()
  const {userGroups, isPending} = useGetGroupsData(telemetryData)
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false)
  const plantId = useUrlParam('plantId')
  const isEmpty = useMemo(
    () => !userGroups?.filter((item) => item.plantId === plantId).length,
    [userGroups, plantId]
  )
  const {updateSearchParams, activeGroup} = usePlantMonitoringUrlState()

  if (isPending) {
    return <Loader />
  }

  const handleGroupItemClick = (group: UserSignalGroup) => {
    updateSearchParams([
      {
        key: 'selected',
        value: activeGroup === group.name ? '' : group.signals.join(',')
      },
      {
        key: 'activeGroup',
        value: activeGroup === group.name ? '' : group.name
      }
    ])
  }

  const isActive = (name: string) => activeGroup && activeGroup === name
  const isWithoutWarnings = (group: UserSignalGroup) =>
    !group.alarms && !group.warnings && !group.modelBreaches

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="space-between"
        {...dataTestId('pm-groups-menu')}
        alignItems={'left'}
        gap={2}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          {isEmpty ? (
            <GroupsEmpty setAddGroupModalOpen={setAddGroupModalOpen} />
          ) : (
            <Box>
              <Box px={3}>
                {activeGroup ? (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    startIcon={<EditIcon />}
                    onClick={() => setAddGroupModalOpen(true)}
                    fullWidth
                  >
                    Edit group
                  </Button>
                ) : (
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    startIcon={<AddIcon />}
                    onClick={() => setAddGroupModalOpen(true)}
                    fullWidth
                  >
                    New group
                  </Button>
                )}
              </Box>
              <Box mt={2}>
                <List>
                  {userGroups
                    .filter((item) => item.plantId === plantId)
                    .map((group) => (
                      <Stack key={group.name}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handleGroupItemClick(group)}
                            color={'primary'}
                            sx={{
                              backgroundColor: isActive(group.name) ? '#E6F0FB' : 'transparent',
                              height: '60px',
                              padding: 1,
                              paddingLeft: 3,
                              paddingRight: 3
                            }}
                          >
                            <ListItemText primary={group.name} />
                            <Stack direction={'row'} gap={1} alignItems={'flex-end'}>
                              <KilnMetricStatus
                                status={'SignalsCount'}
                                value={group.signals.length.toString()}
                              />
                              {isWithoutWarnings(group) ? (
                                <KilnMetricStatus
                                  status={'Ok'}
                                  value={group.signals.length.toString()}
                                />
                              ) : undefined}
                              {group.alarms ? (
                                <KilnMetricStatus
                                  status={'Alarm'}
                                  value={group.alarms?.toString()}
                                />
                              ) : undefined}
                              {group.warnings ? (
                                <KilnMetricStatus
                                  status={'Warning'}
                                  value={group.warnings?.toString()}
                                />
                              ) : undefined}
                              {group.modelBreaches ? (
                                <KilnMetricStatus
                                  status={'ModelBreach'}
                                  value={group.modelBreaches?.toString()}
                                />
                              ) : undefined}
                            </Stack>
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="middle" sx={{mx: 4}} />
                      </Stack>
                    ))}
                </List>
              </Box>
            </Box>
          )}
          {addGroupModalOpen && (
            <AddGroupModal
              userGroups={userGroups}
              isOpen={addGroupModalOpen}
              handleClose={() => setAddGroupModalOpen(false)}
            />
          )}
        </Box>
      </Stack>
    </Box>
  )
}
