import {KilnMetricStatus} from '@hconnect/common/components/kmsStatus/KilnMetricStatus'
import {SignalStatus, LastModelBreach} from '@hconnect/common/components/kmsStatus/types'
import CheckIcon from '@mui/icons-material/Check'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom'

import {LinearLoader} from '../../../shared/components/LinearLoader'
import {WidgetTemplate} from '../../../shared/components/WidgetTemplate'
import {useGetPlantStructure} from '../hooks/useGetPlantStructure'
import {useGetTelemetry} from '../hooks/useGetTelemetry'
import {useGetUpmSignalsMultiple} from '../hooks/useGetUpmSignals'
import {SignalMetadata} from '../types/sensor.types'

export const AssetHealthWidget = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const areaFilter = searchParams.get('assetHealthArea') || 'all'
  const {data: plantStructure, isPending: plantStructurePending} = useGetPlantStructure()

  // all upm areas with at least one signal
  const areas = useMemo(
    () => plantStructure?.children?.filter((area) => area.signalCount) || [],
    [plantStructure]
  )

  // fetch signals for all areas
  const signalQueries = useGetUpmSignalsMultiple(
    areas.map(
      (area) =>
        ({
          entity: 'area',
          entityId: area.id
        }) as const
    )
  )
  const signalQueriesPending = signalQueries.some((query) => query.isPending)

  // get signal metadata to display in list
  const signalsMetadata = useMemo(() => {
    const metadata: {[key: string]: SignalMetadata} = {}

    signalQueries.forEach((query) => {
      if (query.data) {
        query.data.forEach(
          (signal) =>
            (metadata[signal.localName] = {
              localName: signal.localName,
              description: signal.description,
              area: signal.area.name,
              productionLine: signal.productionLine.name,
              source: signal.source,
              equipment: signal.equipment.name,
              svgId: ''
            })
        )
      }
    })
    return metadata
  }, [signalQueries])

  // get all local names per area
  const localNamesPerArea = useMemo(
    () =>
      areas.map((area, index) => ({
        id: area.id,
        signals: signalQueries[index]?.data?.map((signal) => signal.localName) || []
      })),
    [areas, signalQueries]
  )

  // get all local names from all areas
  const allLocalNames = useMemo(
    () => localNamesPerArea.reduce<string[]>((acc, area) => [...acc, ...area.signals], []),
    [localNamesPerArea]
  )

  // get signals for filtered area
  const filteredLocalNames = useMemo(() => {
    if (areaFilter === 'all') {
      return allLocalNames
    }
    return localNamesPerArea.find((area) => area.id === areaFilter)?.signals || []
  }, [areaFilter, localNamesPerArea, allLocalNames])

  // fetch last values for relevant signals
  const {
    data: telemetryData,
    isPending: telemetryPending,
    isFetching
  } = useGetTelemetry(filteredLocalNames, {
    enabled: filteredLocalNames.length > 0 && !signalQueriesPending
  })

  // Get unhealthy signals
  const unhealthySignals = useMemo(() => {
    return (
      telemetryData?.filter(
        (signal) =>
          !!signal.lastModelBreach?.dateTime ||
          signal.threshold.status === 'Warning' ||
          signal.threshold.status === 'Alarm'
      ) || []
    )
  }, [telemetryData])

  const isLoading = plantStructurePending || signalQueriesPending || telemetryPending

  const noData = !isLoading && telemetryData?.length === 0
  const noAlarms = !isLoading && unhealthySignals.length === 0

  return (
    <WidgetTemplate
      xs={12}
      md={12}
      lg={6}
      title={t('widget.assetHealth.title')}
      loading={isLoading}
      maxHeight={400}
      minWidth={330}
      onClick={() => navigate('pm?activeTreeView=signals')}
      headerAction={
        <TextField
          id="area-select"
          select
          label="Area"
          variant="filled"
          sx={{width: 200}}
          value={areaFilter}
          onChange={(e) => setSearchParams({assetHealthArea: e.target.value})}
        >
          <MenuItem value="all">All</MenuItem>
          {areas.map((area) => (
            <MenuItem key={area.id} value={area.id}>
              {area.name}
            </MenuItem>
          ))}
        </TextField>
      }
    >
      <LinearLoader loading={isFetching} />
      {noData && <NoData />}
      {!noData && noAlarms && <NoAlarms />}
      {!noData && !noAlarms && (
        <Box>
          {unhealthySignals.map((signal) => (
            <ListItem
              key={signal.localName}
              sx={{px: 0, gap: 1, width: '100%'}}
              divider
              onClick={(e) => {
                e.stopPropagation()
                void navigate({
                  pathname: 'pm',
                  search: createSearchParams({
                    activeTreeView: 'signals',
                    selected: signal.localName
                  }).toString()
                })
              }}
            >
              <Stack spacing={1} sx={{width: '100%'}}>
                <Stack direction="row" spacing={3}>
                  <ListItemText
                    primary={
                      signal.description
                        ? ` ${signal.localName} > ${signal.description}`
                        : ` ${signal.localName}`
                    }
                  />
                  <Box minWidth="fit-content">
                    <KilnMetricStatus
                      status={resolveStatus({
                        thresholdStatus: signal.threshold.status,
                        modelBreach: signal.lastModelBreach
                      })}
                      value={`${signal.value?.toFixed(1)} ${signal.unit}`}
                    />
                  </Box>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={1}>
                  <Chip
                    color="primary"
                    size="small"
                    label={signalsMetadata[signal.localName].source}
                  />
                  <Chip size="small" label={signalsMetadata[signal.localName].area} />
                  <Chip
                    size="small"
                    label={`Production Line: ${signalsMetadata[signal.localName].productionLine}`}
                  />
                  <Chip size="small" label={signalsMetadata[signal.localName].equipment} />
                </Stack>
              </Stack>
            </ListItem>
          ))}
        </Box>
      )}
    </WidgetTemplate>
  )
}

export const resolveStatus = ({
  thresholdStatus,
  modelBreach
}: {
  thresholdStatus?: SignalStatus
  modelBreach?: LastModelBreach
}) => {
  if (thresholdStatus === 'Alarm' || thresholdStatus === 'Warning') {
    return thresholdStatus
  }
  if (modelBreach) {
    return 'ModelBreach'
  }
  return thresholdStatus
}

const NoData = () => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        gap: 1
      }}
    >
      <Avatar sx={{width: 64, height: 64, bgcolor: '#F7F9FA'}}>
        <CloudOffIcon fontSize="medium" sx={{color: '#668099'}} />
      </Avatar>
      <Typography variant="caption" sx={{color: '#52667A'}}>
        {t('widget.assetHealth.noData')}
      </Typography>
    </Box>
  )
}

const NoAlarms = () => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        gap: 1
      }}
    >
      <Avatar sx={{width: 64, height: 64, bgcolor: '#F2FBF6'}}>
        <CheckIcon fontSize="medium" sx={{color: 'success.light'}} />
      </Avatar>
      <Typography variant="caption" sx={{color: '#52667A'}}>
        {t('widget.assetHealth.noAlarms')}
      </Typography>
    </Box>
  )
}
