import {StationParts} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {KilnLayoutState} from '@hconnect/common/components/kmsStatus'
/* eslint-disable complexity */
import {customAlphabet} from 'nanoid'

import {INITIAL_LAYOUT, STATION, DRIVE} from './KilnConfig.constants'
import {calculateXForAssetPart, getAllSelectedSignals} from './KilnConfig.helpers'
import {LayoutActionType, KilnLayoutAction} from './KilnConfig.types'

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 6)

export const kilnConfigReducer = (
  state: KilnLayoutState,
  action: KilnLayoutAction
): KilnLayoutState => {
  switch (action.type) {
    case LayoutActionType.CHANGE_ROTATION:
      return {
        ...state,
        isRotationClockwise: action.payload
      }
    case LayoutActionType.ADD_THRUST_ROLLER:
      return {
        ...state,
        stations: state.stations.map((station) =>
          station.id === action.payload.id
            ? {
                ...station,
                thrustRollerPosition: action.payload.position,
                width: STATION.WIDTH_TR,
                x: station.x - 20
              }
            : station
        )
      }
    case LayoutActionType.REMOVE_THRUST_ROLLER:
      return {
        ...state,
        stations: state.stations.map((station) =>
          station.id === action.payload.id
            ? {
                ...station,
                thrustRollerPosition: 'none',
                width: STATION.WIDTH_NO_TR,
                x: station.x + 20
              }
            : station
        ),
        selectedSignals: Object.fromEntries(
          Object.entries(state.selectedSignals).filter(
            ([key]) => key !== `${action.payload.id}_${StationParts.THRUST_ROLLER}`
          )
        )
      }
    case LayoutActionType.SET_DUAL_DRIVE:
      return {
        ...state,
        // interactiveOverlayHeight: 550,
        referenceDimensions: {...state.referenceDimensions, height: DRIVE.HEIGHT_DUAL},
        kilnShell: {...state.kilnShell, y: INITIAL_LAYOUT.kilnShell.y + DRIVE.Y_OFFSET_TOP},
        stations: state.stations.map((station) => ({
          ...station,
          y: DRIVE.Y_OFFSET_TOP
        })),
        drive: {
          ...state.drive,
          position: 'double',
          height: DRIVE.HEIGHT_DUAL,
          y: 0
        }
      }
    case LayoutActionType.SET_BOTTOM_DRIVE:
      return {
        ...state,
        // interactiveOverlayHeight: 500,
        referenceDimensions: {
          ...state.referenceDimensions,
          height: INITIAL_LAYOUT.referenceDimensions.height
        },
        kilnShell: {...state.kilnShell, y: INITIAL_LAYOUT.kilnShell.y},
        stations: state.stations.map((station) => ({
          ...station,
          y: 0
        })),
        drive: {
          ...state.drive,
          position: 'bottom',
          height: DRIVE.HEIGHT_SINGLE,
          y: DRIVE.Y_SINGLE
        },
        selectedSignals: Object.fromEntries(
          Object.entries(state.selectedSignals).filter(([key]) => !key.startsWith(state.drive.id))
        )
      }
    case LayoutActionType.SET_TOP_DRIVE:
      return {
        ...state,
        // interactiveOverlayHeight: 550,
        referenceDimensions: {...state.referenceDimensions, height: DRIVE.HEIGHT_DUAL},
        kilnShell: {...state.kilnShell, y: INITIAL_LAYOUT.kilnShell.y + DRIVE.Y_OFFSET_TOP},
        stations: state.stations.map((station) => ({
          ...station,
          y: DRIVE.Y_OFFSET_TOP
        })),
        drive: {
          ...state.drive,
          position: 'top',
          height: DRIVE.HEIGHT_SINGLE,
          y: 0
        },
        selectedSignals: Object.fromEntries(
          Object.entries(state.selectedSignals).filter(([key]) => !key.startsWith(state.drive.id))
        )
      }
    case LayoutActionType.ADD_STATION:
      return {
        ...state,
        stations: [
          ...state.stations,
          {
            id: `station-${nanoid()}`,
            x: calculateXForAssetPart({
              draggableElements: [
                state.drive,
                ...state.stations,
                {
                  id: 'dummy',
                  x: state.referenceDimensions.width,
                  y: 0,
                  width: 0,
                  height: 0
                }
              ],
              assetPartTotalWidth: state.stationOverlayWidth
            }),
            y: state.drive.position === 'bottom' ? 0 : DRIVE.Y_OFFSET_TOP,
            width: STATION.WIDTH_NO_TR,
            height: STATION.HEIGHT,
            thrustRollerPosition: 'none'
          }
        ]
      }
    case LayoutActionType.REMOVE_STATION:
      return {
        ...state,
        stations: state.stations.filter((station) => station.id !== action.payload.id),
        selectedSignals: Object.fromEntries(
          Object.entries(state.selectedSignals).filter(
            ([key]) => !key.startsWith(action.payload.id)
          )
        ),
        axialBalanceSignals: Array.isArray(state.axialBalanceSignals)
          ? {}
          : Object.fromEntries(
              Object.entries(state.axialBalanceSignals).filter(
                ([key]) => !key.startsWith(action.payload.id)
              )
            )
      }
    case LayoutActionType.SET_SELECTED_SIGNALS:
      return {
        ...state,
        selectedSignals: action.payload
      }
    case LayoutActionType.FLIP_MATERIAL_FLOW:
      return {
        ...state,
        materialFlow: state.materialFlow === 'leftToRight' ? 'rightToLeft' : 'leftToRight'
      }
    case LayoutActionType.MOVE_X: {
      const {id, deltaX} = action.payload
      return {
        ...state,
        stations: state.stations.map((station) =>
          station.id === id
            ? {
                ...station,
                x: station.x + deltaX
              }
            : station
        ),
        drive:
          id === state.drive.id
            ? {
                ...state.drive,
                x: state.drive.x + deltaX
              }
            : state.drive
      }
    }
    case LayoutActionType.SET_STATION_NAME:
      return {
        ...state,
        stations: state.stations.map((station) =>
          station.id === action.payload.id
            ? {
                ...station,
                name: action.payload.name
              }
            : station
        )
      }
    case LayoutActionType.SET_DRIVE_NAME:
      return {
        ...state,
        drive: {...state.drive, name: action.payload}
      }
    case LayoutActionType.SET_SPEED_SIGNALS:
      return {
        ...state,
        speedSignals: action.payload
      }
    case LayoutActionType.SET_FEED_SIGNALS:
      return {
        ...state,
        feedSignals: action.payload
      }
    case LayoutActionType.SET_OVALITY_SIGNALS:
      return {
        ...state,
        ovalitySignals: action.payload
      }
    case LayoutActionType.SET_CRANK_SIGNALS:
      return {
        ...state,
        crankSignals: action.payload
      }
    case LayoutActionType.SET_AXIAL_BALANCE_SIGNALS:
      return {
        ...state,
        axialBalanceSignals: action.payload
      }
    case LayoutActionType.VERIFY_HEALTH_INDICATORS: {
      const allSignals = getAllSelectedSignals(state.selectedSignals).map((signal) => signal.id)

      return {
        ...state,
        speedSignals: state.speedSignals.filter((signal) => allSignals.includes(signal.id)),
        feedSignals: state.feedSignals.filter((signal) => allSignals.includes(signal.id)),
        ovalitySignals: state.ovalitySignals.filter((signal) => allSignals.includes(signal.id)),
        crankSignals: Object.fromEntries(
          Object.entries(state.crankSignals)
            .map(([key, signals]) => [
              key,
              signals.filter((signal) => allSignals.includes(signal.id))
            ])
            .filter(([, signals]) => signals.length)
        ),
        axialBalanceSignals: Array.isArray(state.axialBalanceSignals)
          ? {}
          : Object.fromEntries(
              Object.entries(state.axialBalanceSignals).map(([key, config]) => [
                key,
                {
                  ...config,
                  signal:
                    config.signal && allSignals.includes(config.signal.id) ? config.signal : null
                }
              ])
            )
      }
    }
    case LayoutActionType.FLIP_THRUST_ROLLER: {
      return {
        ...state,
        stations: state.stations.map((station) =>
          station.id === action.payload.id
            ? {
                ...station,
                thrustRollerPosition:
                  station.thrustRollerPosition === 'left'
                    ? 'right'
                    : station.thrustRollerPosition === 'right'
                      ? 'left'
                      : station.thrustRollerPosition
              }
            : station
        )
      }
    }
    case LayoutActionType.FLIP_MOTOR: {
      const newSide = state.drive.side === 'left' ? 'right' : 'left'
      const flowBasedSide = state.materialFlow === 'rightToLeft' ? 'right' : 'left' // for backwards compatibility
      return {
        ...state,
        drive: {
          ...state.drive,
          side: state.drive.side ? newSide : flowBasedSide
        }
      }
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
