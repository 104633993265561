import {Box, CircularProgress, LinearProgress} from '@mui/material'
import {Chart} from 'chart.js/auto'
import moment, {Moment} from 'moment'
import {MutableRefObject, useMemo} from 'react'

import {BaseChart} from '../../../../shared/components/graphs/BaseChart'
import {areaChartOptions} from '../../../../shared/components/graphs/config/multiLineWithZoom.config'
import {rangeSelectPlugin} from '../../../../shared/components/graphs/plugins'
import {ChartError} from '../../components/ChartError'
import {useSensors} from '../../context/SensorsContext'
import {useGetSignalPlotData} from '../../hooks/useGetSignalPlotData'

import {getMultiSignalData, getMultiSignalYAxes} from './chartHelpers'

interface MultiScalableChartProps {
  sensorIds: string[]
  chartRef?: MutableRefObject<Chart | null>
  scaleToShowThreshold?: boolean
  range?: {
    timeFrom: Moment
    timeTo: Moment
  }
  rangeSelectCallback: ({timeFrom, timeTo}: {timeFrom: Moment; timeTo: Moment}) => void
}

export const MultiScalableChart = ({
  sensorIds,
  range,
  rangeSelectCallback,
  scaleToShowThreshold,
  chartRef
}: MultiScalableChartProps) => {
  const {sensorData} = useSensors()
  const {
    data,
    isPending: isLoading,
    isFetching
  } = useGetSignalPlotData({
    sensorIds: sensorIds,
    ...range,
    normalized: true
  })

  const plugins = useMemo(() => [rangeSelectPlugin], [])

  const chartData = useMemo(() => getMultiSignalData(sensorData, data), [sensorData, data])

  const options = useMemo(() => {
    const yAxes = getMultiSignalYAxes(chartData, scaleToShowThreshold)
    return areaChartOptions({
      minimalDateRange: 60 * 5,
      scaleToShowThreshold,
      yAxes,
      onRangeSelection: (range: {startDate: string; endDate: string}) => {
        if (range?.startDate && range?.endDate)
          rangeSelectCallback({
            timeFrom: moment(range?.startDate),
            timeTo: moment(range?.endDate)
          })
      }
    })
  }, [rangeSelectCallback, scaleToShowThreshold, chartData])

  return isLoading ? (
    <Box sx={{height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
  ) : !isLoading && !chartData.labels?.length ? (
    <ChartError />
  ) : (
    <div>
      {isFetching ? <LinearProgress /> : <div style={{height: '4px'}} />}
      <BaseChart data={chartData} options={options} plugins={plugins} extChartRef={chartRef} />
    </div>
  )
}
