import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {Box, Typography, Button} from '@mui/material'
import React, {forwardRef} from 'react'

import {DragHandle} from '../../../../shared/components/DragHandle'

interface ChartWrapperProps {
  title?: string | JSX.Element
  subtitle?: string
  children: React.ReactNode
  onRemove?: () => void
  onEdit?: () => void
  onMouseLeave?: () => void
  style?: React.CSSProperties
  dragHandle?: JSX.Element
}

export const ChartWrapper = forwardRef<HTMLDivElement, ChartWrapperProps>(
  ({title, subtitle, children, style, onRemove, dragHandle, onMouseLeave, onEdit}, ref) => {
    return (
      <Box
        ref={ref}
        display="flex"
        height="fit-content"
        flexDirection="column"
        gap={1}
        sx={{...style}}
        data-test-id={'chart-wrapper'}
      >
        <Box display="flex" gap={1} justifyContent="space-between" alignItems="flex-start">
          <Box display="flex" gap={1} alignItems="flex-start">
            {dragHandle}
            <Box>
              {title && (
                <Typography variant="h5" sx={{alignSelf: 'flex-start'}} color="text.primary">
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography variant="caption" sx={{alignSelf: 'flex-start'}}>
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            {onEdit && (
              <Button
                variant="text"
                startIcon={<EditIcon />}
                color="primary"
                sx={{pt: 0, pb: 0}}
                onClick={() => onEdit()}
                data-test-id={'graph-remove-button'}
              />
            )}
            {onRemove && (
              <Button
                variant="text"
                startIcon={<CloseIcon />}
                color="error"
                sx={{pt: 0, pb: 0}}
                onClick={() => onRemove()}
                data-test-id={'graph-remove-button'}
              />
            )}
          </Box>
        </Box>
        <Box onMouseLeave={onMouseLeave}>{children}</Box>
      </Box>
    )
  }
)

interface SortableChartWrapperProps {
  id: string
  title?: string
  subtitle?: string
  children: React.ReactNode
  onRemove: () => void
  onEdit?: () => void
  onMouseLeave?: () => void
}

export const SortableChartWrapper = ({
  id,
  title,
  subtitle,
  children,
  onRemove,
  onEdit,
  onMouseLeave
}: SortableChartWrapperProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <ChartWrapper
      ref={setNodeRef}
      title={title}
      subtitle={subtitle}
      style={style}
      dragHandle={<DragHandle attributes={attributes} listeners={listeners} />}
      onRemove={onRemove}
      onEdit={onEdit}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ChartWrapper>
  )
}
