import {
  SignalStatus,
  LastModelBreach,
  ThresholdLimit,
  SignalReading
} from '@hconnect/common/components/kmsStatus/types'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import {Chip, ChipProps, Stack, Typography, useTheme, alpha} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {LastSensorReading} from './LastSensorReading'

export const SignalStatusValue = ({
  lastReading,
  lastModelBreach,
  unit
}: {
  lastReading?: SignalReading
  lastModelBreach?: LastModelBreach
  unit?: string
}) => {
  const signalStatus = lastReading?.status
  const isAlarmOrWarning = signalStatus === 'Alarm' || signalStatus === 'Warning'
  const modelBreach = !!lastModelBreach

  // !! model breach chips are rendered in two places to have better layout
  // !! when both model breach and thresholds are shown
  return (
    <Stack spacing={0.5} alignItems="flex-end">
      <Stack spacing={1} direction="row" alignItems="center">
        {!isAlarmOrWarning && modelBreach && (
          <Status status="ModelBreach" thresholdLimit={lastReading?.limit} />
        )}
        {isAlarmOrWarning && <Status status={signalStatus} thresholdLimit={lastReading?.limit} />}
        <Typography
          variant="h5"
          noWrap
          minWidth="fit-content"
          color={
            signalStatus === 'Alarm'
              ? 'error.light'
              : signalStatus === 'Warning'
                ? 'warning.light'
                : modelBreach
                  ? '#8C3CC9'
                  : 'text.primary'
          }
        >{`${lastReading?.value?.toFixed(2) ?? '-'} ${unit}`}</Typography>
      </Stack>

      <Stack alignItems="flex-start" direction="row" spacing={1}>
        {isAlarmOrWarning && modelBreach && (
          <Status status="ModelBreach" thresholdLimit={lastReading?.limit} />
        )}
        {lastReading?.recorded && <LastSensorReading lastReading={lastReading?.recorded} />}
      </Stack>
    </Stack>
  )
}

const Status = ({
  status,
  disabled,
  onClick,
  thresholdLimit
}: {
  status?: SignalStatus | 'ModelBreach'
  disabled?: boolean
  onClick?: () => void
  thresholdLimit?: ThresholdLimit
}) => {
  const theme = useTheme()
  const {t} = useTranslation()

  const {color, backgroundColor, icon, value} = ((): {
    color: ChipProps['color']
    backgroundColor: string
    icon?: JSX.Element
    value: string
  } => {
    switch (status) {
      case 'Warning':
        return {
          color: 'warning',
          backgroundColor: theme.palette.warning.light,
          icon: <WarningIcon sx={{fontSize: 13}} />,
          value: getThresholdLimit(thresholdLimit) ?? ''
        }
      case 'Alarm':
        return {
          color: 'error',
          backgroundColor: theme.palette.error.light,
          icon: <ErrorIcon sx={{fontSize: 13}} />,
          value: getThresholdLimit(thresholdLimit) ?? ''
        }
      case 'ModelBreach':
        return {
          color: undefined,
          backgroundColor: '#8C3CC9',
          icon: <WarningIcon style={{color: '#8C3CC9'}} sx={{fontSize: 13}} />,
          value: t('sensorList.predictionBreach')
        }
      default:
        return {
          color: 'default',
          backgroundColor: theme.palette.grey[600],
          value: '-'
        }
    }
  })()

  return (
    <Chip
      variant="outlined"
      color={color}
      icon={icon}
      label={value}
      disabled={disabled}
      onClick={() => onClick?.()}
      sx={{
        height: '24px',
        background: alpha(backgroundColor, 0.04),
        fontSize: 12,
        fontWeight: 500,
        borderRadius: '4px',
        color: backgroundColor,
        border: `1.5px solid ${alpha(backgroundColor, 0.08)}`,
        '&&:hover': {
          background: alpha(backgroundColor, 0.08)
        },
        '&& .MuiChip-label': {
          pr: 0.5,
          pl: 1
        }
      }}
    />
  )
}

const getThresholdLimit = (thresholdLimit?: ThresholdLimit) => {
  switch (thresholdLimit) {
    case ThresholdLimit.HIGH:
      return 'High'
    case ThresholdLimit.HIGH_HIGH:
      return 'High High'
    case ThresholdLimit.LOW:
      return 'Low'
    case ThresholdLimit.LOW_LOW:
      return 'Low Low'
    default:
      return undefined
  }
}
