import {AssetConfigurationDto} from '../KilnConfig.types'

export const assetToSignals = (assetConfig?: AssetConfigurationDto) => {
  const signals: string[] = []
  if (assetConfig && assetConfig.payload) {
    const payload = assetConfig.payload

    payload.layout.feedSignals?.forEach((signal) => signals.push(signal.id))
    payload.layout.speedSignals?.forEach((signal) => signals.push(signal.id))
    // payload.layout.axialBalanceSignals?.forEach((signal) => signals.push(signal.id))
    payload.layout.ovalitySignals?.forEach((signal) => signals.push(signal.id))

    if (payload.layout.axialBalanceSignals && !Array.isArray(payload.layout.axialBalanceSignals)) {
      Object.values(payload.layout.axialBalanceSignals).forEach((config) => {
        if (config.signal) {
          signals.push(config.signal.id)
        }
      })
    }

    if (payload.layout.crankSignals) {
      const crankSignals = payload.layout.crankSignals
      Object.keys(crankSignals).forEach((key) =>
        crankSignals[key].map((signal) => signals.push(signal.id))
      )
    }
    if (payload.layout.selectedSignals) {
      const selectedSignals = payload.layout.selectedSignals
      Object.keys(selectedSignals).forEach((key) =>
        selectedSignals[key].map((signal) => signals.push(signal.id))
      )
    }
  }

  return signals
}
