import {DndContext, DragEndEvent, closestCenter} from '@dnd-kit/core'
import {restrictToParentElement} from '@dnd-kit/modifiers'
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import MergeTypeIcon from '@mui/icons-material/MergeType'
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan'
import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSensors} from '../../context/SensorsContext'
import {ActiveView} from '../../types/sensorDetails.types'
import {getSensorGroupString} from '../../utils/sensor.utils'
import {MultiSensorChart} from '../charts/MultiSensorChart'
import {SortableChartWrapper} from '../charts/SensorChartWrapper'
import {SingleSensorChart} from '../charts/SingleSensorChart'

import {ChartSettings} from './ChartSettings'

interface DefaultViewProps {
  combinedSensors: string[][]
  handleRemoveCombineGroup: (groupIndex: number) => void
  setActiveView: React.Dispatch<React.SetStateAction<ActiveView>>
  sensorsOrder: string[]
  onDragEnd: (e: DragEndEvent) => void
  scaleToShowThreshold: boolean
  setScaleToShowThreshold: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedGroupId: React.Dispatch<React.SetStateAction<number | undefined>>
  timeWindow: number
  setTimeWindow: React.Dispatch<React.SetStateAction<number>>
}

export const DefaultView = ({
  combinedSensors,
  handleRemoveCombineGroup,
  setActiveView,
  sensorsOrder,
  onDragEnd,
  scaleToShowThreshold,
  setScaleToShowThreshold,
  timeWindow,
  setTimeWindow,
  setSelectedGroupId
}: DefaultViewProps) => {
  const {t} = useTranslation()
  const {toggleSensorSelected, selectedSensors} = useSensors()
  const hideText = useMediaQuery('(max-width:700px)')

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext items={sensorsOrder} strategy={verticalListSortingStrategy}>
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" color="text.primary">
              {t('sensorDetails.heading')}
            </Typography>
            <ToggleButtonGroup
              value={timeWindow}
              size="small"
              exclusive
              color="primary"
              onChange={(_, newTimeWindow: number) => {
                newTimeWindow && setTimeWindow(newTimeWindow)
              }}
              aria-label="Time Resolution"
              sx={{
                border: 'none !important',
                '& .MuiToggleButton-root': {border: 'none !important'}
              }}
              data-test-id="time-range"
            >
              <ToggleButton value={6} data-test-id={'6h-button-range'}>
                6h
              </ToggleButton>
              <ToggleButton value={12} data-test-id={'12h-button-range'}>
                12h
              </ToggleButton>
              <ToggleButton value={24} data-test-id={'24h-button-range'}>
                24h
              </ToggleButton>
              <ToggleButton value={72} data-test-id={'3d-button-range'}>
                3d
              </ToggleButton>
              <ToggleButton value={168} data-test-id={'7d-button-range'}>
                7d
              </ToggleButton>
              <ToggleButton value={720} data-test-id={'30d-button-range'}>
                30d
              </ToggleButton>
            </ToggleButtonGroup>

            <Box display="flex">
              <Button
                variant="outlined"
                disabled={selectedSensors.length < 2}
                startIcon={<MergeTypeIcon />}
                onClick={() => setActiveView('combine')}
                data-test-id="compare-signals-btn"
              >
                {!hideText && t('sensorDetails.compare')}
              </Button>
              <Button
                variant="text"
                startIcon={<SettingsOverscanIcon />}
                onClick={() => setActiveView('popout')}
                data-test-id="popout-view-btn"
              >
                {!hideText && t('sensorDetails.popOut')}
              </Button>
              <ChartSettings
                scaleToShowThreshold={scaleToShowThreshold}
                setScaleToShowThreshold={setScaleToShowThreshold}
              />
            </Box>
          </Box>
          <Stack color="text.primary" spacing={2}>
            {sensorsOrder.map((id) => {
              if (id.startsWith('combined')) {
                const groupIndex = parseInt(id.split('-')[1])
                const sensorIds: string[] = combinedSensors[groupIndex]
                if (!sensorIds?.length) return null
                return (
                  <SortableChartWrapper
                    id={id}
                    key={`${id}-${timeWindow}`}
                    title={`Multiple Sensors (${sensorIds.join(', ')})`}
                    onRemove={() => handleRemoveCombineGroup(groupIndex)}
                    onEdit={() => setSelectedGroupId(groupIndex)}
                  >
                    <MultiSensorChart
                      sensorIds={sensorIds}
                      deltaHours={timeWindow}
                      scaleToShowThreshold={scaleToShowThreshold}
                    />
                  </SortableChartWrapper>
                )
              }
              const sensor = selectedSensors.find((sensor) => sensor.localName === id)
              return (
                sensor && (
                  <SortableChartWrapper
                    key={`${id}-${timeWindow}`}
                    id={id}
                    title={`${sensor.localName} ${sensor.description}`}
                    subtitle={getSensorGroupString(sensor)}
                    onRemove={() => toggleSensorSelected(sensor.localName)}
                  >
                    <SingleSensorChart
                      sensorId={id}
                      deltaHours={timeWindow}
                      scaleToShowThreshold={scaleToShowThreshold}
                    />
                  </SortableChartWrapper>
                )
              )
            })}
          </Stack>
        </Stack>
      </SortableContext>
    </DndContext>
  )
}
