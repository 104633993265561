import {SignalData} from '@hconnect/common/components/kmsStatus'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useGlobalContext} from '../../../shared/hooks/useGlobalContext'

export type CombineModalProps = {
  onCancel: () => void
  groupId?: number
  onDone: (sensorGroups: string[][]) => void
  selectedSensors: SignalData[]
  combinedSensors: string[][]
}

export const CombineModal = ({
  onCancel,
  onDone,
  selectedSensors,
  combinedSensors,
  groupId
}: CombineModalProps) => {
  const {t} = useTranslation()
  const [sensorGroups, setSensorGroups] = useState<string[][]>(() =>
    sensorGroupsInit(selectedSensors, combinedSensors)
  )
  const [selectedGroupId] = useState(groupId !== undefined ? groupId : sensorGroups.length)
  const {state} = useGlobalContext()

  useEffect(() => {
    if (state && state.user) {
      trackEvent('kmsCombinedView', {
        userId: state.user.userId,
        country: state.user.countryCode,
        product: 'Maintain',
        subProduct: 'KMS'
      })
    }
  }, [state])

  useEffect(() => {
    setSensorGroups((groups) => sensorGroupsInit(selectedSensors, groups))
  }, [selectedSensors])

  const onCheeck = (sensorId: string, checked: boolean) => {
    const currentGroupIndex = findGroupById(sensorGroups, sensorId)
    setSensorGroups((groups) => {
      const newGroups = structuredClone(groups)

      if (newGroups.length > 0 && currentGroupIndex >= 0) {
        newGroups[currentGroupIndex] = newGroups[currentGroupIndex].filter((id) => id !== sensorId)
      }

      // add to existing group TODO CHANGE WHEN MORE GROUPS
      if (checked) {
        if (newGroups.length === selectedGroupId) {
          newGroups.push([])
        }
        newGroups[selectedGroupId].push(sensorId)
      }

      return newGroups
    })
  }

  const isSensorChecked = (sensorId: string): boolean => {
    if (sensorGroups && sensorGroups[selectedGroupId]) {
      return sensorGroups[selectedGroupId].find((id) => id === sensorId) !== undefined
    }

    return false
  }

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle component="div" flexDirection="column" display="flex">
        <Typography variant="h2">{t('sensorDetails.compare')}</Typography>
      </DialogTitle>
      <DialogContent sx={{display: 'flex', gap: 4, flexDirection: 'column'}}>
        <Typography variant="caption" fontSize={'16px'}>
          Select the graphs you want to combine{' '}
        </Typography>
        <Box sx={{maxHeight: '400px', overflowY: 'scroll'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sensor name</TableCell>
                <TableCell>Sensor ID</TableCell>
                <TableCell>Unit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSensors.map((sensor) => (
                <TableRow key={`${sensor.localName}-combine-graph`}>
                  <TableCell>
                    {' '}
                    <Checkbox
                      size="small"
                      checked={isSensorChecked(sensor.localName)}
                      onChange={(e) => onCheeck(sensor.localName, e.target.checked)}
                    />
                    {sensor.localName}
                  </TableCell>
                  <TableCell>{sensor.description}</TableCell>
                  <TableCell>{sensor.unit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant={'h4'}>
          {sensorGroups && sensorGroups[selectedGroupId]
            ? sensorGroups[selectedGroupId].length
            : '0'}{' '}
          graphs selected
        </Typography>
        <Box>
          <Button startIcon={<Close />} variant="outlined" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            startIcon={<Check />}
            color="primary"
            variant="contained"
            onClick={() => onDone(sensorGroups)}
            sx={{marginLeft: 1}}
          >
            Combine
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const findGroupById = (sensorGroups: string[][], sensorId?: string | null) => {
  if (!sensorId) return -1
  return sensorGroups.findIndex((group) => group.includes(sensorId))
}

const sensorGroupsInit = (selectedSensors: SignalData[], combinedSensors: string[][]) => {
  // filter combined sensors that were deselected
  combinedSensors.forEach((group, index) => {
    combinedSensors[index] = group.filter((sensorId) =>
      selectedSensors.find((sensor) => sensor.localName === sensorId)
    )
  }, combinedSensors)

  return combinedSensors.filter((group) => group.length > 1)
}
