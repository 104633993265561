// CONSTANTS - possibly retrieved from asset catalogue in the future
import {KilnLayoutState} from '@hconnect/common/components/kmsStatus'

export const STATION = {
  HEIGHT: 244,
  WIDTH_NO_TR: 68,
  WIDTH_TR: 114
}

export const DRIVE = {
  HEIGHT_SINGLE: 273,
  HEIGHT_DUAL: 330,
  Y_OFFSET_TOP: 42,
  Y_SINGLE: 18,
  WIDTH: 146
}

// 1211 291 - single drive
// 1211 351 - double drive
// 42 - offset for other parts if double drive
export const INITIAL_LAYOUT: KilnLayoutState = {
  isRotationClockwise: true,
  materialFlow: 'rightToLeft',
  interactiveOverlayHeight: 600,
  stationOverlayWidth: 182,
  referenceDimensions: {width: 1344, height: 291},
  kilnShell: {id: 'kiln', name: 'Kiln', x: 0, y: 54 + 0, width: 1344, height: 136},
  drive: {
    id: 'open-drive',
    name: 'Open Drive',
    x: 800,
    y: 18,
    width: 146,
    height: 273,
    position: 'bottom',
    side: 'left'
  }, // y = 18 if top or double
  stations: [],
  selectedSignals: {},
  speedSignals: [],
  feedSignals: [],
  ovalitySignals: [],
  axialBalanceSignals: {},
  crankSignals: {}
}
