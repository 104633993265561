import {dataTestId} from '@hconnect/uikit'
import {Loader, CardBox} from '@hconnect/uikit/src/lib2'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import SensorsIcon from '@mui/icons-material/Sensors'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import {
  Box,
  Stack,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Typography
} from '@mui/material'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {NodeApi, Tree, TreeApi} from 'react-arborist'
import {useTranslation} from 'react-i18next'
import useResizeObserver from 'use-resize-observer'

import {useSignalMetadata} from '../../context/SignalMetadataContext'
import {SignalTelemetryProvider} from '../../context/SignalTelemetryContext'
import {usePlantMonitoringUrlState} from '../../hooks/usePlantMonitoringUrlState'
import {useSignalTreeData} from '../../hooks/useSignalTreeData'

import {Groups} from './Groups/Groups'
import {SignalTreeFilters} from './SignalTreeFilters'
import {Node, TreeNode} from './SignalTreeNode'

enum SignalTreeViews {
  SIGNALS = 'signals',
  GROUPS = 'groups'
}

export const SignalTreeContainer = () => {
  const {activeTreeView, updateSearchParams} = usePlantMonitoringUrlState()
  const {localNames} = useSignalMetadata()

  return (
    <CardBox px={0} display="flex" flexDirection="column" gap={2} pt={3}>
      <Stack direction="column" justifyContent="space-between" alignItems={'left'} gap={2} px={3}>
        <ToggleButtonGroup
          color="primary"
          value={activeTreeView}
          exclusive
          onChange={(_, value) => {
            typeof value === 'string' &&
              updateSearchParams([
                {
                  key: 'activeTreeView',
                  value
                }
              ])
          }}
          sx={{backgroundColor: '#F0F2F5', border: 'none !important'}}
        >
          <ToggleButton
            value="signals"
            sx={{flexGrow: 1, border: 'none !important', display: 'flex', gap: 1}}
          >
            <SensorsIcon />
            Signals
          </ToggleButton>
          <ToggleButton
            value="groups"
            sx={{flexGrow: 1, border: 'none !important', display: 'flex', gap: 1}}
          >
            <FolderOutlinedIcon />
            Groups
          </ToggleButton>
        </ToggleButtonGroup>
        {activeTreeView === (SignalTreeViews.SIGNALS as string) && <SignalTreeFilters />}
      </Stack>
      <SignalTelemetryProvider localNames={localNames}>
        {activeTreeView === (SignalTreeViews.SIGNALS as string) && <SignalTree />}
        {activeTreeView === (SignalTreeViews.GROUPS as string) && <Groups />}
      </SignalTelemetryProvider>
    </CardBox>
  )
}

const SignalTree = () => {
  const {t} = useTranslation()
  const {isPending: signalsPending} = useSignalMetadata()
  const {search, selectedIds, equipmentOnly, updateSearchParams} = usePlantMonitoringUrlState()
  const [isInitial, setIsInitial] = useState<boolean>(true)
  const treeData = useSignalTreeData()
  const {ref: treeContainerRef, width, height} = useResizeObserver()
  const treeRef = useRef<TreeApi<TreeNode>>()

  const handleFilterTextChange = useCallback(
    (e) => {
      const text = e.target.value
      updateSearchParams([{key: 'search', value: text}])
    },
    [updateSearchParams]
  )

  const debouncedSetFilterText = debounce(handleFilterTextChange, 300)

  const handleNodesSelected = (nodes: NodeApi<TreeNode>[]) => {
    const newSelectedIds = nodes
      .map((node) => node.data.signalData?.localName)
      .filter((id): id is string => !!id)

    if (newSelectedIds.length !== selectedIds.length) {
      if (isInitial && newSelectedIds.length === 0) {
        setIsInitial(false)
      } else {
        updateSearchParams([
          {
            key: 'selected',
            value: newSelectedIds.join(',')
          },
          {
            key: 'activeGroup',
            value: ''
          }
        ])
      }
    }
  }

  // sync url and tree selection, currently react-arborist does not support controlled selection properly
  useEffect(() => {
    if (signalsPending) return
    const tree = treeRef.current
    const treeSignalIds = tree?.selectedNodes
      .map((node) => node.data.signalData?.localName)
      .filter((name): name is string => !!name)

    const selectionIsSame =
      selectedIds.length === treeSignalIds?.length &&
      selectedIds.every((id) => treeSignalIds?.includes(id))

    if (selectionIsSame) return
    tree?.setSelection({
      ids: selectedIds,
      anchor: null,
      mostRecent: null
    })
  }, [signalsPending, selectedIds])

  if (signalsPending) {
    return <Loader />
  }

  return (
    <>
      <Box>
        <Stack
          direction="column"
          justifyContent="space-between"
          {...dataTestId('janus-plant-structure-menu')}
          alignItems={'left'}
          gap={2}
        >
          <Box px={3} display="flex" flexDirection="column" gap={1}>
            <TextField
              label="Search"
              fullWidth
              variant="filled"
              defaultValue={search}
              onChange={debouncedSetFilterText}
              {...dataTestId('plant-structure-text-filter')}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: 'transparent',
                  borderRadius: '6px',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'transparent'
                  }
                }
              }}
            />
            <Button
              onClick={() =>
                updateSearchParams([{key: 'equipmentOnly', value: (!equipmentOnly).toString()}])
              }
              size="small"
              variant="text"
              startIcon={equipmentOnly ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
              sx={{alignSelf: 'flex-start'}}
            >
              {equipmentOnly ? 'Show all' : 'Equipment only'}
            </Button>
          </Box>
          <Box ref={treeContainerRef} height={'55vh'}>
            {treeData.length === 0 && (
              <Typography textAlign="center" variant="subtitle1">
                {t('label.noData')}
              </Typography>
            )}
            <Tree
              ref={treeRef}
              data={treeData}
              openByDefault={true}
              width={width}
              rowHeight={60}
              height={height}
              indent={14}
              searchTerm={search}
              searchMatch={(node) => node.data.label.toLowerCase().includes(search.toLowerCase())}
              onSelect={(nodes) => handleNodesSelected(nodes)}
            >
              {Node}
            </Tree>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
