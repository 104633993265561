import {KilnInfo} from '@hconnect/common/components/kmsStatus'
import {HttpError} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {createRetryFunction} from '../../../shared/helpers/queryHelpers'
import {useAxios} from '../../../shared/hooks/useApi'

export const QueryKey = 'kiln-info'

export const useGetKilnInfo = (
  plantUpmId: string | undefined,
  options?: UseQueryOptions<KilnInfo[], AxiosError<HttpError>>
) => {
  const axiosInstance = useAxios()

  return useQuery<KilnInfo[], AxiosError<HttpError>>({
    queryKey: [QueryKey, plantUpmId],
    queryFn: async () => {
      const path = `/kms/${plantUpmId}/kilns`
      return (await axiosInstance.get<KilnInfo[]>(path)).data
    },
    staleTime: 1000 * 60 * 60,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: (_, query) => query.state.data === undefined,
    retry: createRetryFunction(3),
    retryDelay: 1000 * 3,
    enabled: plantUpmId !== undefined,
    // allow the callee to override the default options
    ...options
  })
}
