import {HttpError} from '@hconnect/common/types'
import {useQuery, keepPreviousData} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import moment from 'moment'
import qs from 'qs'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {QueryOptions2Type} from '../../../shared/types/common.types'
import {SignalTelemetry} from '../types/sensor.types'

export const QueryKey = 'telemetry'

export const useGetTelemetry = (
  localNames: string[],
  options?: QueryOptions2Type<SignalTelemetry[], AxiosError<HttpError>>
) => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return useQuery<SignalTelemetry[], AxiosError<HttpError>>({
    queryKey: [QueryKey, plantId, localNames],
    queryFn: async () => {
      const path = `/upm/telemetry/plant/${plantId}/last-value`
      return (
        await axiosInstance.get(path, {
          params: {
            LocalNames: localNames,
            CadAlertQueryLimit: moment().subtract(1, 'day').toISOString()
          },
          paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
        })
      ).data
    },
    placeholderData: keepPreviousData,
    refetchInterval: 1000 * 60,
    ...options
  })
}
