import {useQueries} from '@tanstack/react-query'

import {useAxios} from '../../../../../shared/hooks/useApi'
import {usePlantUpmId} from '../../../../shared/hooks/usePlantUpmId'
import {AssetConfigurationDto} from '../KilnConfig.types'

export const QueryKey = 'asset-config'

export const useGetAssetConfigs = (assetIds: string[]) => {
  const axiosInstance = useAxios()
  const plantUpmId = usePlantUpmId()

  const queriesResult = useQueries({
    queries: assetIds.map((assetId) => ({
      queryKey: [QueryKey, plantUpmId, assetId],
      queryFn: async () => {
        const path = `asset-config/kms-data/plant/${plantUpmId}/upm-type/Department`
        return (
          await axiosInstance.get<AssetConfigurationDto>(path, {
            params: {
              typeUpmId: assetId
            }
          })
        ).data
      },
      // if config not created yet, we will get 404
      // throwOnError: (error) => error?.response?.status !== 404,
      // retry: (retryCount, error) => error?.response?.status !== 404 && retryCount < 3,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: plantUpmId !== undefined && assetId !== undefined
    }))
  })

  return {
    data: queriesResult.map((result) => result.data || [])
  }
}
