import {PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {useTranslation} from 'react-i18next'

import {PlantProvider} from '../../kms/context/PlantContext'
import {SignalMetadataProvider} from '../context/SignalMetadataContext'

import {ControlPanel} from './ControlPanel'
import {SignalTreeContainer} from './SiginalTree/SignalTree'
import {SignalCharts} from './SignalCharts'

export const PM = () => {
  const {t} = useTranslation()

  return (
    <SignalMetadataProvider>
      <Box display="flex" justifyContent="space-between" gap={1}>
        <PageTitle
          mb={0}
          data-test-id="kms-page-title"
          maxWidth="fit-content"
          display={{xs: 'none', md: 'block'}}
        >
          {t('general.pmTitle')}
        </PageTitle>
        <ControlPanel />
      </Box>
      <Grid container spacing={2} mt={2}>
        <Grid xs={12} md={12} lg={5} xl={4}>
          <SignalTreeContainer />
        </Grid>
        <Grid xs={12} md={12} lg={7} xl={8}>
          <SignalCharts />
        </Grid>
      </Grid>
    </SignalMetadataProvider>
  )
}

export const PMContainer = () => {
  return (
    <PageContainer display="flex" flexDirection="column" bgcolor="#01407F" flexGrow={1}>
      <PlantProvider>
        <PM />
      </PlantProvider>
    </PageContainer>
  )
}
