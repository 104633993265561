import React, {useContext, createContext} from 'react'

import {useGetTelemetry} from '../../shared/hooks/useGetTelemetry'
import {SignalTelemetry} from '../../shared/types/sensor.types'

interface SignalTelemetryContext {
  telemetryData: Record<string, SignalTelemetry> | undefined
  isPending: boolean
}

export const SensorsContext = createContext<SignalTelemetryContext>(
  null as unknown as SignalTelemetryContext
)

type SensorsProviderProps = {
  children: React.ReactNode
  localNames: string[]
}

// ! This provider should be used as low in the tree as possible, as the data is changing every 30 seconds and all children will rerender
export const SignalTelemetryProvider = ({children, localNames}: SensorsProviderProps) => {
  const telemetryQuery = useGetTelemetry(localNames, {
    enabled: !!localNames.length,
    // @ts-expect-error the select is impossible to type correctly
    select: (data) => data.reduce((acc, signal) => ({...acc, [signal.localName]: signal}), {})
  })

  // set the correct type from select manually
  const telemetryData = telemetryQuery.data as Record<string, SignalTelemetry> | undefined

  return (
    <SensorsContext.Provider value={{telemetryData, isPending: telemetryQuery.isPending}}>
      {children}
    </SensorsContext.Provider>
  )
}

export const useSignalTelemetry = () => useContext(SensorsContext)
