import {CardBox} from '@hconnect/uikit/src/lib2'
import CallMergeIcon from '@mui/icons-material/CallMerge'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import LayersClearOutlinedIcon from '@mui/icons-material/LayersClearOutlined'
import {Alert, Box, Button, CircularProgress, Stack, Typography} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import moment from 'moment'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as NoSelected} from '../../shared/assets/NoSelected.svg'
import {SignalSourceIcon} from '../../shared/components/SignalSourceIcon'
import {ChartWrapper} from '../../shared/containers/charts/SensorChartWrapper'
import {SingleScalableChart} from '../../shared/containers/charts/SingleScalableChart'
import {useSignalMetadata} from '../context/SignalMetadataContext'
import {usePlantMonitoringUrlState} from '../hooks/usePlantMonitoringUrlState'

import {AddGroupModalGraphs} from './SiginalTree/Groups/GroupsModal'

export const SignalCharts = () => {
  const {t} = useTranslation()
  const {selectedIds, updateSearchParams, activeGroup} = usePlantMonitoringUrlState()
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)

  const handleRemoveSignal = useCallback(
    (id: string) => {
      updateSearchParams([
        {
          key: 'selected',
          value: selectedIds.filter((selectedId) => selectedId !== id).join(',')
        }
      ])
    },
    [selectedIds, updateSearchParams]
  )

  if (selectedIds.length === 0) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%" spacing={3}>
        <NoSelected />
        <Typography
          variant="body1"
          fontStyle="italic"
          color="rgba(255, 255, 255, 0.6)"
          maxWidth="300px"
        >
          {t('sensorDetails.noSelected')}
        </Typography>
      </Stack>
    )
  }

  return (
    <CardBox gap={2} display="flex" flexDirection={'column'}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{xs: 'column', sm: 'row'}}
        gap={2}
      >
        <Typography variant="h3">{`${selectedIds.length} signals selected`}</Typography>
        <Stack direction="row" spacing={1.5}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<LayersClearOutlinedIcon />}
            sx={{border: '1px solid #E8ECF0', borderRadius: '6px'}}
            onClick={() => updateSearchParams([{key: 'selected', value: ''}])}
          >
            Clear all
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CallMergeIcon />}
            sx={{border: '1px solid #E8ECF0', borderRadius: '6px'}}
          >
            Combine graphs
          </Button>
          {!activeGroup && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<CreateNewFolderOutlinedIcon />}
              sx={{borderRadius: '6px'}}
              onClick={() => setAddModalOpen(true)}
            >
              Save as new group
            </Button>
          )}
          <AddGroupModalGraphs isOpen={addModalOpen} handleClose={() => setAddModalOpen(false)} />
        </Stack>
      </Box>
      <Grid container spacing={2}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore  this is actually needed here, there is some discrepancy in the tsconfig */}
        {selectedIds.toReversed().map((id) => (
          <SignalChart id={id} key={id} onRemove={handleRemoveSignal} />
        ))}
      </Grid>
    </CardBox>
  )
}

type ChartDateRange = {
  timeFrom: moment.Moment
  timeTo: moment.Moment
}

export const SignalChart = ({id, onRemove}: {id: string; onRemove}) => {
  const {columns, scaleToThresholds, startDate, endDate, updateSearchParams, resetZoom} =
    usePlantMonitoringUrlState()
  const {signalMap, isPending} = useSignalMetadata()
  const signal = signalMap.get(id)
  const [zoomRange, setZoomRange] = useState<ChartDateRange | null>(null)

  const range: ChartDateRange = useMemo(
    () => ({
      timeFrom: moment(startDate),
      timeTo: moment(endDate)
    }),
    [startDate, endDate]
  )

  useEffect(() => {
    if (resetZoom) {
      setZoomRange(null)
      updateSearchParams([{key: 'resetZoom', value: ''}])
    }
  }, [resetZoom, updateSearchParams])

  if (isPending) {
    return (
      <Grid xs={12 / columns}>
        <Box
          sx={{height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          <CircularProgress />
        </Box>
      </Grid>
    )
  }

  if (!signal) {
    return (
      <Grid xs={12 / columns}>
        <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
          <Alert severity="error" sx={{flexGrow: 1}}>
            Signal not found
          </Alert>
        </Box>
      </Grid>
    )
  }

  return (
    <Grid xs={12 / columns}>
      <ChartWrapper
        subtitle={signal.description}
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <SignalSourceIcon source={signal.source ?? ''} />
            <Typography noWrap>{signal.localName}</Typography>
          </Box>
        }
        onRemove={() => onRemove(id)}
      >
        <SingleScalableChart
          sensorId={id}
          range={zoomRange ?? range}
          rangeSelectCallback={(value) => setZoomRange(value)}
          scaleToShowThreshold={scaleToThresholds}
        />
      </ChartWrapper>
    </Grid>
  )
}
