import {HealthIndicatorsApi} from '@hconnect/common/components/kmsStatus'
import {HttpError} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {createRetryFunction} from '../../../shared/helpers/queryHelpers'
import {useAxios} from '../../../shared/hooks/useApi'
import {usePlantUpmId} from '../../shared/hooks/usePlantUpmId'

export const QueryKey = 'health-indicators'

export const useGetHealthIndicators = ({
  kilnId,
  options
}: {
  kilnId: string
  options?: UseQueryOptions<HealthIndicatorsApi, AxiosError<HttpError>>
}) => {
  const axiosInstance = useAxios()
  const plantUpmId = usePlantUpmId()

  return useQuery<HealthIndicatorsApi, AxiosError<HttpError>>({
    queryKey: [QueryKey, plantUpmId, kilnId],
    queryFn: async () => {
      const path = `/kms/${plantUpmId}/signal/health-indicators`
      return (
        await axiosInstance.get<HealthIndicatorsApi>(path, {
          params: {
            departmentId: kilnId
          }
        })
      ).data
    },
    refetchInterval: 1000 * 30,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: false,
    retry: createRetryFunction(3),
    retryDelay: 1000 * 3,
    enabled: plantUpmId !== undefined,
    // allow the callee to override the default options
    ...options
  })
}
