import React, {useContext, createContext, useMemo} from 'react'

import {usePlant} from '../../kms/context/PlantContext'
import {useGetUpmSignalsMultiple} from '../../shared/hooks/useGetUpmSignals'
import {SignalsListType} from '../../shared/types/sensor.types'

interface SignalMetadataContext {
  isPending: boolean
  localNames: string[]
  signals: SignalsListType[]
  signalMap: Map<string, SignalsListType>
}

export const SignalMetadataContext = createContext<SignalMetadataContext>(
  null as unknown as SignalMetadataContext
)

type SensorsProviderProps = {
  children: React.ReactNode
}

export const SignalMetadataProvider = ({children}: SensorsProviderProps) => {
  const {areas} = usePlant()
  const signalQueries = useGetUpmSignalsMultiple(
    areas?.map(
      (area) =>
        ({
          entity: 'area',
          entityId: area.id
        }) as const
    ) ?? [],
    {
      enabled: areas !== undefined
    }
  )

  const signalsPending = signalQueries.some((query) => query.isPending)

  const signals = useMemo(() => signalQueries.flatMap((query) => query.data ?? []), [signalQueries])
  const signalMap = useMemo(
    () => new Map(signals.map((signal) => [signal.localName, signal])),
    [signals]
  )

  const localNames = useMemo(() => {
    if (signalsPending) return []
    return signalQueries.flatMap((query) => query.data?.map((signal) => signal.localName) ?? [])
  }, [signalsPending, signalQueries])

  return (
    <SignalMetadataContext.Provider
      value={{localNames, isPending: signalsPending, signals, signalMap}}
    >
      {children}
    </SignalMetadataContext.Provider>
  )
}

export const useSignalMetadata = () => useContext(SignalMetadataContext)
