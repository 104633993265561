import {useMemo} from 'react'

import {SignalTelemetry} from '../../shared/types/sensor.types'
import {TreeNode} from '../components/SiginalTree/SignalTreeNode'
import {useSignalMetadata} from '../context/SignalMetadataContext'
import {useSignalTelemetry} from '../context/SignalTelemetryContext'

import {StatusOption, usePlantMonitoringUrlState} from './usePlantMonitoringUrlState'

export const useSignalTreeData = () => {
  const {equipmentOnly, providers, status} = usePlantMonitoringUrlState()
  const {isPending: signalsPending, signals} = useSignalMetadata()
  const {isPending: telemetryPending, telemetryData} = useSignalTelemetry()

  // filter local names based on telemetry status
  const filteredIds = useMemo(() => {
    if (!status.length || telemetryPending || signalsPending) return null
    return signals
      .filter((signal) =>
        checkSignalStatus({signal: telemetryData?.[signal.localName], statusFilter: status})
      )
      .map((signal) => signal.localName)
  }, [signals, signalsPending, status, telemetryData, telemetryPending])

  // TODO: we need some generic solution in the future
  // we should request extension to the UPM "structure" endpoint to allow fetching also signals and
  // filtering based on areas etc. and then replace this logic
  const {completeTree, equipmentTree} = useMemo(() => {
    if (signalsPending) return {completeTree: [], equipmentTree: []}

    const areaMap: Record<string, TreeNode> = {}
    const departmentMap: Record<string, TreeNode> = {}
    const equipmentMap: Record<string, TreeNode> = {}

    signals
      .filter((signal) => !providers.length || providers.includes(signal.source))
      .filter((signal) => !status.length || filteredIds?.includes(signal.localName))
      .forEach((signal) => {
        if (!equipmentMap[signal.equipment.id]) {
          equipmentMap[signal.equipment.id] = {
            id: signal.equipment.id,
            label: signal.equipment.name,
            parentId: signal.department.id,
            children: []
          }
        }

        if (!departmentMap[signal.department.id]) {
          departmentMap[signal.department.id] = {
            id: signal.department.id,
            label: signal.department.name,
            parentId: signal.area.id,
            children: []
          }
        }

        if (!areaMap[signal.area.id]) {
          areaMap[signal.area.id] = {
            id: signal.area.id,
            label: signal.area.name,
            parentId: 'root',
            children: []
          }
        }

        equipmentMap[signal.equipment.id]?.children?.push({
          id: signal.localName,
          label: signal.localName,
          parentId: signal.equipment.id,
          signalData: {
            source: signal.source,
            description: signal.description,
            name: signal.name,
            localName: signal.localName
          },
          children: null
        })
      }, {})

    // put together the tree structure
    Object.values(equipmentMap).forEach((equipment) => {
      departmentMap[equipment.parentId]?.children?.push(equipment)
    })

    Object.values(departmentMap).forEach((department) => {
      areaMap[department.parentId]?.children?.push(department)
    })

    return {completeTree: Object.values(areaMap), equipmentTree: Object.values(equipmentMap)}
  }, [signalsPending, signals, providers, status.length, filteredIds])

  return equipmentOnly ? equipmentTree : completeTree
}

const checkSignalStatus = ({
  signal,
  statusFilter
}: {
  signal?: SignalTelemetry
  statusFilter: StatusOption[]
}) => {
  if (!statusFilter.length) return true
  if (!signal) return false
  const thresholdCheck = statusFilter.includes(signal.threshold.limit)
  const modelBreachCheck = statusFilter.includes('ModelBreach') && signal.lastModelBreach?.dateTime
  return thresholdCheck || modelBreachCheck
}
