import {SignalData} from '@hconnect/common/components/kmsStatus/types'
import {HttpError} from '@hconnect/common/types'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {createRetryFunction} from '../../../shared/helpers/queryHelpers'
import {useAxios} from '../../../shared/hooks/useApi'
import {QueryOptionsType} from '../../../shared/types/common.types'

import {usePlantUpmId} from './usePlantUpmId'

export const QueryKey = 'sensors'

export const useGetSignals = <TData = SignalData[],>({
  options,
  kilnId
}: {
  options?: QueryOptionsType<SignalData[], AxiosError<HttpError>, TData>
  kilnId?: string
}) => {
  const axiosInstance = useAxios()
  const plantUpmId = usePlantUpmId()

  return useQuery<SignalData[], AxiosError<HttpError>, TData>({
    queryKey: [QueryKey, plantUpmId, kilnId],
    queryFn: async () => {
      const path = `/kms/${plantUpmId}/signals`
      return (
        await axiosInstance.get<SignalData[]>(path, {
          params: {
            departmentId: kilnId
          }
        })
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchInterval: 1000 * 30,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: (_, query) => query.state.data === undefined,
    retry: createRetryFunction(3),
    retryDelay: 1000 * 3,
    enabled: plantUpmId !== undefined && kilnId !== undefined,
    ...options
  })
}
