import {Check} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import SensorsIcon from '@mui/icons-material/Sensors'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {useStoreUserValue} from '../../../../shared/hooks/userStorage/storeValue'
import {SignalsListType} from '../../../../shared/types/sensor.types'
import {useSignalMetadata} from '../../../context/SignalMetadataContext'
import {useGetGroupsData} from '../../../hooks/useGetGroupsData'
import {usePlantMonitoringUrlState} from '../../../hooks/usePlantMonitoringUrlState'

export const AddGroupModalGraphs = ({
  isOpen,
  handleClose
}: {
  isOpen: boolean
  handleClose: () => void
}) => {
  const {mutateAsync: saveGroup} = useStoreUserValue()
  const [groupName, setGroupName] = useState<string>()
  const [isError, setIsError] = useState<string>()
  const {selectedIds} = usePlantMonitoringUrlState()
  const {userGroups, isPending} = useGetGroupsData()
  const [selectedSignals, setSelectedSignals] = useState<SignalsListType[]>([])
  const {signals} = useSignalMetadata()
  const plantId = useUrlParam('plantId')

  useEffect(() => {
    const selected = selectedIds
      .map((id) => signals.find((signal) => signal.localName === id))
      .filter((s) => s !== undefined)
    // @ts-expect-error wrong type
    setSelectedSignals(selected)
  }, [selectedIds, signals])

  const submitGroups = async () => {
    if (!groupName || groupName === '') {
      setIsError('Group name is required')
      return
    }

    const duplicate = userGroups.find((g) => g.name === groupName)
    if (duplicate) {
      setIsError('Group name is duplicate please select another name')
      return
    }
    if (selectedSignals.length > 0) {
      const tmp = [...userGroups]
      tmp.push({
        name: groupName,
        signals: selectedSignals.map((s) => s.localName),
        plantId: plantId
      })

      await saveGroup({
        keyIdentifier: 'pm-user-groups',
        value: JSON.stringify(tmp)
      })
    }
    onClose()
  }

  const handleDelete = (localName: string) => {
    setSelectedSignals(selectedSignals.filter((s) => s.localName !== localName))
  }

  const onClose = () => {
    setIsError(undefined)
    setGroupName(undefined)
    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} aria-labelledby="add-group-modal" fullWidth>
      <DialogTitle id="add-group-modal-title">
        <Typography variant={'h4'}>New group</Typography>
      </DialogTitle>
      <DialogContent sx={{overflowY: 'hidden'}}>
        <TextField
          label={'Group name'}
          fullWidth
          error={isError !== undefined}
          helperText={isError}
          sx={{marginTop: 2}}
          onChange={(e) => {
            setGroupName(e.target.value)
            setIsError(undefined)
          }}
        />
        <Box mb={2} mt={2}>
          <Divider />
        </Box>
        <Box>
          {selectedSignals.map((signal) => (
            <Chip
              icon={<SensorsIcon />}
              key={signal.localName}
              sx={{mb: 1}}
              label={`${signal.equipment.name} > ${signal.description}`}
              deleteIcon={<CloseIcon />}
              onDelete={() => handleDelete(signal.localName)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{backgroundColor: '#FFF'}}
          width={'100%'}
        >
          <Typography variant={'h3'}>{`${selectedIds.length} sensors selected`}</Typography>
          <Stack gap={2} direction={'row'}>
            <Button
              onClick={onClose}
              variant={'outlined'}
              disabled={isPending}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={submitGroups}
              variant={'contained'}
              disabled={isPending}
              startIcon={<Check />}
            >
              Save new Group
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
