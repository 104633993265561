import {SignalData} from '@hconnect/common/components/kmsStatus/types'
import {ChartData, ChartDataset} from 'chart.js'

import {SignalGraphData} from '../../types/sensor.types'
import {roundValue} from '../../utils/sensor.utils'

export type TCustomChartData = {
  labels: string[]
  datasets: {
    pointRadius: number
    pointBorderWidth: number
    pointHoverRadius: number
    label: string
    data: number[]
    borderWidth: number
    unit: string
    values: number[]
    borderColor: string
    backgroundColor: string
    spanGaps: boolean
  }[]
}
const COLORS = [
  '#016AD4',
  '#D15C02',
  '#009D93',
  '#F9DC5C',
  '#E36588',
  '#42F2F7',
  '#5F0F40',
  '#01D402',
  '#D401D3'
]

function* chartColorGenerator() {
  for (const color of COLORS) {
    yield color
  }
}

type LabelsOverride = {
  avgValues?: string
  maxValues?: string
  minValues?: string
  modelMean?: string
  modelMin?: string
  modelMax?: string
}

const getPointProps = (firstNValues: number[]) => {
  const nonNullValues = firstNValues.filter((value) => value !== null).length
  return {
    pointRadius: nonNullValues < 6 ? 2 : 0,
    pointBorderWidth: nonNullValues < 6 ? 2 : 0,
    pointHoverRadius: nonNullValues < 6 ? 3 : 2
  }
}

export const getSingleSignalData = ({
  data,
  labelsOverride
}: {
  data?: SignalGraphData
  labelsOverride?: LabelsOverride
}): ChartData => {
  if (!data?.data?.length) return {labels: [], datasets: []}
  const signalData = data.data[0]
  const datasets: ChartDataset[] = [
    {
      label: labelsOverride?.avgValues || 'Signal',
      data: signalData.values.avgValues,
      borderWidth: 2,
      borderColor: '#016AD4',
      backgroundColor: '#016AD4',
      spanGaps: true,
      ...getPointProps(signalData.values.avgValues.slice(0, 10))
    }
  ]

  if (signalData.values?.maxValues?.length) {
    const color = '#E36588'
    datasets.push({
      label: labelsOverride?.maxValues || 'Signal Max',
      data: signalData.values.maxValues,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 2,
      borderWidth: 2,
      spanGaps: true,
      borderColor: color,
      backgroundColor: color,
      hidden: true
    })
  }

  if (signalData.values?.minValues?.length) {
    const color = '#01D402'
    datasets.push({
      label: labelsOverride?.minValues || 'Signal Min',
      data: signalData.values.minValues,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 2,
      borderWidth: 2,
      spanGaps: true,
      borderColor: color,
      backgroundColor: color,
      hidden: true
    })
  }

  if (signalData.values?.modelMean?.length) {
    datasets.push({
      label: labelsOverride?.modelMean || 'Model',
      data: signalData.values.modelMean,
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      borderWidth: 2,
      borderColor: '#8C3CC9',
      backgroundColor: '#8C3CC9',
      spanGaps: true
    })
  }

  if (signalData.values?.modelMax?.length && signalData.values?.modelMin?.length) {
    datasets.push({
      label: labelsOverride?.modelMin || 'modelMin',
      data: signalData.values.modelMin,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      borderWidth: 1,
      spanGaps: true,
      pointRadius: 0,
      showLine: false
    })
    datasets.push({
      label: labelsOverride?.modelMax || 'modelMax',
      data: signalData.values.modelMax,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      borderWidth: 1,
      pointRadius: 0,
      fill: '-1',
      spanGaps: true,
      showLine: false,
      backgroundColor: 'rgba(140,60,201,0.1)'
    })
  }

  return {
    labels: data?.labels ? data.labels : [],
    datasets: datasets ? datasets : []
  }
}

export const getMultiSignalYAxes = (
  chartData: TCustomChartData,
  scaleToShowThreshold?: boolean
) => {
  const units: Record<string, {min: number; max: number}> = {}
  chartData.datasets.forEach((item) => {
    if (units[item.unit]) {
      const minVal = Math.min(...item.values)
      const maxVal = Math.max(...item.values)
      units[item.unit] = {
        min: units[item.unit].min < minVal ? units[item.unit].min : minVal,
        max: units[item.unit].max > maxVal ? units[item.unit].max : maxVal
      }
    } else {
      const minVal = Math.min(...item.values)
      const maxVal = Math.max(...item.values)
      units[item.unit] = {
        min: minVal,
        max: maxVal
      }
    }
  })

  const yAxes = {}
  const colorGenerator = chartColorGenerator()

  Object.keys(units).forEach((key, index) => {
    const axeName = `y${index + 1}`
    const color =
      colorGenerator.next().value ?? `#${Math.floor(Math.random() * 16777215).toString(16)}`
    yAxes[axeName] = {
      suggestedMax: scaleToShowThreshold ? units[key].max * 6 : units[key].max,
      suggestedMin: scaleToShowThreshold ? units[key].max * -6 : units[key].min,
      grid: {
        display: false
      },
      border: {
        display: false
      },
      ticks: {
        color: color,
        padding: 2,
        callback: function (value) {
          const parsedValue = typeof value === 'string' ? value : roundValue(value)
          return `${parsedValue} ${key}`
        }
      }
    }
  })

  return yAxes
}

export const getMultiSignalData = (signals?: SignalData[], data?: SignalGraphData) => {
  const colorGenerator = chartColorGenerator()
  const datasets = data?.data?.map((signalData) => {
    const signal = signals?.find((signal) => signal.localName === signalData.localName)
    const color =
      colorGenerator.next().value ?? `#${Math.floor(Math.random() * 16777215).toString(16)}`
    return {
      label: signal?.description ?? '',
      data: signalData.values.valuesNormalized ?? [],
      borderWidth: 2,
      unit: signal?.unit ?? '',
      values: signalData.values.avgValues,
      borderColor: color,
      backgroundColor: color,
      spanGaps: true,
      ...getPointProps(signalData?.values?.valuesNormalized?.slice(0, 10) ?? [])
    }
  })

  return {
    labels: data?.labels ? data.labels : [],
    datasets: datasets ? datasets : []
  }
}
