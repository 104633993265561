import {KilnLayout} from '@hconnect/common/components/kmsStatus/types'

export enum LayoutActionType {
  CHANGE_ROTATION = 'changeRotation',
  ADD_THRUST_ROLLER = 'addThrustRoller',
  REMOVE_THRUST_ROLLER = 'removeThrustRoller',
  SET_DUAL_DRIVE = 'setDualDrive',
  SET_BOTTOM_DRIVE = 'setBottomDrive',
  SET_TOP_DRIVE = 'setTopDrive',
  ADD_STATION = 'addStation',
  REMOVE_STATION = 'removeStation',
  SET_SELECTED_SIGNALS = 'setSelectedSignals',
  FLIP_MATERIAL_FLOW = 'flipMaterialFlow',
  MOVE_X = 'moveX',
  SET_STATION_NAME = 'setStationName',
  SET_SPEED_SIGNALS = 'setSpeedSignals',
  SET_FEED_SIGNALS = 'setFeedSignals',
  SET_DRIVE_NAME = 'setDriveName',
  SET_OVALITY_SIGNALS = 'setOvalitySignals',
  SET_CRANK_SIGNALS = 'setCrankSignals',
  VERIFY_HEALTH_INDICATORS = 'verifyHealthIndicators',
  FLIP_THRUST_ROLLER = 'flipThrustRoller',
  FLIP_MOTOR = 'flipMotor',
  SET_AXIAL_BALANCE_SIGNALS = 'setAxialBalanceSignals'
}

export interface KilnLayoutAction {
  type: LayoutActionType
  payload?: any
}

export type AssetConfigurationDto = {
  metadata: AssetMetadata
  payload: {
    layout: KilnLayout
    configuration: KilnConfiguration
    signalMappings: SignalMapping[]
  }
}

type AssetMetadata = {
  configurationType: 'KmsData'
  plantId: string
  upmType: 'Department'
  typeUpmId: string
}

type KilnConfiguration = {
  name: string | null
  dimensions: {
    length: number | string
    diameter: number | string
  }
  isRotationClockwise: boolean
  feedSignals: string[]
  speedSignals: string[]
  ovalitySignals: string[] | null
  crankSignals: string[][] | null
  axialMovementSignals: AxialMovementSignal[] | null
}

export type SignalMapping = {
  svgId: string
  signals: string[]
  group: string
}

type AxialMovementSignal = {
  localName: string
  position1Threshold: number | null
  position2Threshold: number | null
}
